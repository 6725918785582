import { Component, Input } from '@angular/core';
import { DsColors, Icons, IconStyles } from '@levelaccess/design-system';

import { BaseCellComponent, ICellConfig } from '../base-cell/base-cell.component';

export interface IDSSeverityCellConfig extends ICellConfig {
  icon: Icons;
  class: string;
}

@Component({
  selector: 'table-cell-ds-icon-text',
  styleUrls: ['../base-cell/base-cell.component.scss'],
  template: `
    <div #ref class="ds-severity-cell">
      <ds-icon class="margin-right-xs {{ config.class }}" [icon]="config.icon" [iconStyle]="IconStyles.Solid"></ds-icon>
      <span>{{ config.text | titlecase }}</span>
    </div>
  `,
})
export class DsSeverityCellComponent extends BaseCellComponent {
  protected readonly Icons: typeof Icons = Icons;
  protected readonly IconStyles: typeof IconStyles = IconStyles;
  protected readonly colors: typeof DsColors = DsColors;

  @Input() public config: IDSSeverityCellConfig;
}
