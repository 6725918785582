import { chain, Dictionary, isObject, keyBy, transform } from 'lodash';

import { char } from '../constants/string';
import { subdomainPattern } from '../constants/tenant';
import { GB, MB, KB } from '../constants/data';

export interface UrlSubDomain {
  subDomain: string;
  parentDomain: string;
}

export type TypeOfKey<Type, Key extends keyof Type> = Type[Key] extends (...args: any[]) => any
  ? ReturnType<Type[Key]>
  : Type[Key];

export class SharedCommonUtility {
  public static isNullish(obj: any | null): obj is null | undefined {
    return obj === null || obj === undefined;
  }

  public static notNullish(obj: any | null): obj is Exclude<any, null | undefined> {
    return !SharedCommonUtility.isNullish(obj);
  }

  public static notNullishOrEmpty(obj: any | null): boolean {
    return !SharedCommonUtility.isNullishOrEmpty(obj);
  }

  /**
   * Determines truthy state of a boolean value that may be formatted as a string, such as in Angular boolean inputs.
   */
  public static isBooleanStringTruthy(value: boolean | string): boolean {
    return value === true || value === 'true';
  }

  public static elementsNotNullish<T>(obj: T[]): boolean {
    return SharedCommonUtility.notNullish(obj) && obj.every((element: any) => SharedCommonUtility.notNullish(element));
  }

  public static isNullishOrEmpty(obj: string | ArrayLike<any>): boolean {
    return SharedCommonUtility.isNullish(obj) || obj.length === 0;
  }

  public static isStringEmpty(str: string): boolean {
    return SharedCommonUtility.isNullish(str) || str.trim().length === 0;
  }

  public static removeProtocolFromUrl(uri: string): string {
    return uri.trim().replace(/(^[^:/]*:\/\/)/, '');
  }

  public static encodeURIParam(param: string | number | boolean): string {
    function replacer(c: string): string {
      return '%' + c.charCodeAt(0).toString(16);
    }

    return encodeURIComponent(param).replace(/[!'()*~]/g, replacer);
  }

  public static getDomainPatternFromUrl(url: string): string[] {
    const checkDomain: RegExp = /^(?:[^.]+\.)*(?:[^.]+\.[^.\/?#&]+)/;
    const matched: RegExpMatchArray = new URL(url).host.replace(/^www\./, '').match(checkDomain);

    if (matched) {
      return [matched[0], '*.' + matched[0]];
    }
    return [];
  }

  public static getDomainPattern(domain: string): string[] {
    const url: string = SharedCommonUtility.getNormalizedUrlString(domain.replace('*.', ''));
    return SharedCommonUtility.getDomainPatternFromUrl(url);
  }

  public static urlPatternToRegexp(pattern: string): RegExp {
    let regexpPattern: string = '^' + SharedCommonUtility.removeProtocolFromUrl(pattern);

    // Add optional port
    const normalizedUrl: URL = SharedCommonUtility.getNormalizedUrl(pattern.replace('*.', ''));
    if (normalizedUrl.port.length === 0) {
      regexpPattern = regexpPattern.replace(normalizedUrl.hostname, `${normalizedUrl.hostname}(:\\d+)?`);
    }

    // Escape dots
    regexpPattern = regexpPattern.replace(/\./g, '\\.');

    // Replace glob pattern to regexp
    regexpPattern = regexpPattern.replace(/\*/g, '[^/]*[^.]?');

    // Replace leading slash
    regexpPattern = regexpPattern.replace(/\/+$/, '');

    // Add optional path and query params
    regexpPattern += `([\\/?#].*)?$`;

    return new RegExp(regexpPattern, 'i');
  }

  /**
   * Converts URL to RegExp. Supports regexes in URL by default. If using with user provided values, escapeUrl
   * should be set to true.
   *
   * @param url
   * @param escapeUrl
   */
  public static localUrlToRegex(url: string): RegExp {
    let regexpPattern: string = '^' + url;

    // Add optional query params
    regexpPattern += `([?#].*)?$`;

    return new RegExp(regexpPattern, 'i');
  }

  public static getTypeOf(obj?: any): string {
    const defaultType: string = 'unknown';

    if (arguments.length !== 1) {
      return defaultType;
    }

    if (typeof obj === 'undefined') {
      return 'undefined';
    }

    if (obj === null) {
      return 'null';
    }

    return Object.prototype.toString.call(obj).slice(8, -1).toLowerCase();
  }

  /**
   * @deprecated replace with lodash's {@link _.has}
   */
  public static hasKey<K extends string>(obj: unknown, key: K): obj is Record<K, unknown> {
    const keys: string[] = key.split('.');
    const len: number = keys.length;
    let o: any = obj;
    let result: boolean = true;

    if (o === null) {
      result = false;
      return result;
    }

    for (let i: number = 0; i < len; i += 1) {
      if (typeof o !== 'object' || o === null || Object.prototype.hasOwnProperty.call(o, keys[i]) === false) {
        result = false;
        break;
      }

      o = o[keys[i]];
    }

    return result;
  }

  public static getMissedOrEmptyKeys<T>(obj: T, keys: Array<keyof T>, failEmptyArray: boolean = false): Array<keyof T> {
    return keys.filter((key: keyof T): boolean => SharedCommonUtility.isMissed(obj[key], failEmptyArray));
  }

  public static isMissed(val: any, emptyArrayMissed: boolean = false): boolean {
    if (SharedCommonUtility.isNullish(val)) {
      return true;
    }

    return (typeof val === 'string' && val.trim().length === 0) || (emptyArrayMissed && Array.isArray(val) && val.length === 0);
  }

  /**
   * @deprecated unused
   */
  public static setKey(obj: Record<string, unknown>, path: string, value: any): Record<string, unknown> {
    const pList: string[] = path.split('.');
    const key: string = pList.pop();

    function createPropertyWithValue(accumulator: Record<string, unknown>, currentValue: string): Record<string, unknown> {
      if (typeof accumulator[currentValue] === 'undefined') {
        accumulator[currentValue] = {};
      }
      return accumulator[currentValue] as Record<string, unknown>;
    }

    const pointer: Record<string, unknown> = pList.reduce(createPropertyWithValue, obj);

    pointer[key] = value;

    return obj;
  }

  public static withLeadingZeros(sourceValue: number | string, targetLength: number): string {
    let res: string = String(sourceValue);
    while (res.length < targetLength) {
      res = '0' + res;
    }
    return res;
  }

  public static percent(partial: number, total: number): number {
    if (this.isNullish(total) || total === 0) {
      return 0;
    }

    return Math.floor((partial / total) * 100);
  }

  public static randomRange(minVal: number = 0, max: number = 1000000): number {
    const min: number = Math.ceil(minVal);
    return Math.floor(Math.random() * (Math.floor(max) - min)) + min;
  }

  public static isDateValid(date: Date): date is Date {
    return date instanceof Date && isNaN(date.getTime()) === false;
  }

  /**
   * @deprecated replace with lodash's {@link _.pick}
   */
  public static pick<T, K extends keyof T>(value: T, ...props: Array<K | string>): Pick<T, K> | any {
    const result: Pick<T, K> = {} as any;

    for (const prop of props) {
      if ((prop as any) in (value as any)) {
        result[prop as K] = value[prop as K];
      } else {
        const path: string = prop as string;
        if (SharedCommonUtility.hasKey(value, path)) {
          const deepValue: any = path.split('.').reduce((prev: any, current: string) => prev[current], value);
          SharedCommonUtility.setKey(result, path, deepValue);
        }
      }
    }

    return result;
  }

  /**
   * doesn't mutate the input parameter [object]
   *
   * @param object
   * @returns a new object which is a duplicate of [object] with nullish entries removed
   */
  public static removeNullishKeys<T>(object: T): T {
    const result: T = { ...object };
    Object.keys(result).forEach((key: string) => {
      if (SharedCommonUtility.isNullish(result[key])) {
        delete result[key];
      }
    });
    return result;
  }

  public static findDuplicateValues(values: string[]): string[] {
    return chain(values)
      .countBy()
      .pickBy((count: number) => count > 1)
      .keys()
      .value();
  }

  public static pickArray<T, K extends keyof T>(values: T[], ...props: Array<K | string>): Array<Pick<T, K> | any> {
    return values.map((value: T) => this.pick(value, ...props));
  }

  /**
   * @deprecated replace with lodash's {@link _.groupBy}
   */
  public static arrayToMap<T, K extends keyof T>(objects: T[], key: K): Map<TypeOfKey<T, K>, T> {
    function getKey(object: T): TypeOfKey<T, K> {
      return typeof object[key] === 'function' ? (object[key] as unknown as () => void).call(object) : object[key];
    }

    return new Map<TypeOfKey<T, K>, T>(objects.map((object: T) => [getKey(object), object]));
  }

  public static isNativeMethod(methodName: any): boolean {
    const regEx: RegExp = new RegExp('^(function|object)$', 'i');
    const argumentType: string = typeof methodName;
    let str: string;

    if (regEx.test(argumentType)) {
      // Returns a string representing the object and check for [native code] string
      str = String(methodName);

      return str.indexOf('[native code]') !== -1;
    }

    return false;
  }

  public static async sleep(milliseconds?: number): Promise<void> {
    function promiseAction(resolve: () => void): void {
      setTimeout(resolve, milliseconds);
    }

    return new Promise(promiseAction);
  }

  public static getLoggableAxiosError(error: any): string {
    return `method: ${error.config?.method}, url: ${error.config?.url}, message: ${error.message}, responseData: ${JSON.stringify(
      error.response?.data,
    )}, responseStatus: ${error.response?.status}, responseHeaders: ${JSON.stringify(error.response?.headers)}`;
  }

  public static promiseFunctions<T>(): {
    resolve: (data?: T) => any;
    reject: (error: any) => void;
    promise: Promise<T>;
  } {
    let resolve: (data?: T) => any;
    let reject: (error: any) => void;
    const promise: Promise<T> = new Promise((res: (data?: T) => any, rej: (error: any) => void) => {
      resolve = res;
      reject = rej;
    });
    return { resolve, reject, promise };
  }

  /**
   * Returns an insecurely generated string of alphanumerics, lowercase.
   * After a few calls, it is possible to predict what Math.random() will return next. And so, it is considered insecure.
   * See the server/client versions of getRandomSecureString if you need a secure CSPRNG.
   *
   * @param length
   */
  public static getRandomInsecureString(length: number = 16): string {
    return Math.random()
      .toString(36)
      .substring(2, 2 + length);
  }

  public static getLinksFromText(text: string): string[] {
    const splitText: RegExp = new RegExp('(?:' + char.new_line + ')');

    const noEmptyString = (path: string): boolean => {
      return path.trim().length > 0;
    };

    return text
      .split(splitText)
      .filter(noEmptyString)
      .map((domain: string): string => domain.trim())
      .map(SharedCommonUtility.getNormalizedUrlString);
  }

  /**
   * Returns a URL object built from the string passed as a parameter.
   * If the url provided does not include the protocol, "https://" will be added as the default protocol.
   *
   * @param url
   */
  public static getNormalizedUrl(url: string): URL {
    if (typeof url !== 'string' || url.trim().length === 0) {
      throw new TypeError('The "url" param is empty');
    }

    let fixedUrl: string = url;
    if (url.startsWith('https://') === false && url.startsWith('http://') === false) {
      fixedUrl = `https://${SharedCommonUtility.removeProtocolFromUrl(url)}`;
    }

    return new URL(fixedUrl);
  }

  public static getNormalizedUrlString(url: string): string {
    return SharedCommonUtility.getNormalizedUrl(url).toJSON();
  }

  public static isUrlAuthorized(url: string, domains: RegExp[]): boolean {
    if (Array.isArray(domains) === false || domains.length === 0) {
      return true;
    }

    const urlWithoutProtocol: string = SharedCommonUtility.removeProtocolFromUrl(url);

    return domains.some((reg: RegExp) => reg.test(urlWithoutProtocol));
  }

  public static hasUrlsNotInDomain(domain: string, urlsToCheck: string[], guard: Function = Array.prototype.some): boolean {
    const domainRegExps: RegExp[] = SharedCommonUtility.getDomainPattern(domain).map(SharedCommonUtility.urlPatternToRegexp);

    const notInDomain = (link: string): boolean => SharedCommonUtility.isUrlAuthorized(link, domainRegExps) === false;

    return urlsToCheck.length > 0 && guard.call(urlsToCheck, notInDomain);
  }

  public static areUrlsAuthorized(urls: string[], authorizedDomains: string[]): boolean {
    if (SharedCommonUtility.isNullishOrEmpty(authorizedDomains)) {
      return true;
    }

    const authorizedDomainReg: RegExp[] = authorizedDomains.flatMap((domain: string) =>
      SharedCommonUtility.getDomainPattern(domain).map(SharedCommonUtility.urlPatternToRegexp),
    );
    return (
      urls.length > 0 &&
      urls.every((url: string): boolean => {
        return SharedCommonUtility.isUrlAuthorized(url, authorizedDomainReg);
      })
    );
  }

  /**
   * Parses the URL and returns the subdomain and parent domain.
   * Useful to extract the tenant domain and verify the environment in the base tenant URL.
   */
  public static getUrlSubDomain(href: string): UrlSubDomain | null {
    if (SharedCommonUtility.isStringEmpty(href)) {
      return null;
    }

    try {
      const url: URL = new URL(href);
      const [subDomain, ...parentDomainParts]: string[] = url.hostname.split('.');
      const notQualifiedDomain = parentDomainParts.length < 2 || parentDomainParts.some(SharedCommonUtility.isStringEmpty);
      if (notQualifiedDomain || SharedCommonUtility.isStringEmpty(subDomain)) {
        return null;
      }
      const parentDomain: string = parentDomainParts.join('.');
      return { subDomain, parentDomain };
    } catch (error) {
      return null;
    }
  }

  /**
   * Converts URLSearchParams to an object.
   * Useful when typings do not have the iterable implementation from `lib.dom.iterable.d.ts`.
   * More info: https://stackoverflow.com/q/71510368/2401947
   */
  public static convertUrlSearchParamsToObject(searchParams: URLSearchParams): Record<string, string> {
    const result: Record<string, string> = {};
    searchParams.forEach((value: string, key: string) => {
      result[key] = value;
    });
    return result;
  }

  /**
   * Extracts the query string of a URL and returns them as an object.
   */
  public static getUrlQueryString(href: string): Record<string, string> | null {
    if (SharedCommonUtility.isStringEmpty(href)) {
      return null;
    }

    try {
      const url: URL = new URL(href);
      if (SharedCommonUtility.isStringEmpty(url.search)) {
        return null;
      }
      const queryString: URLSearchParams = new URLSearchParams(url.search);
      return SharedCommonUtility.convertUrlSearchParamsToObject(queryString);
    } catch (error) {
      return null;
    }
  }

  public static isValidEmail(email: string): boolean {
    /*
      this regex prevents simple mistakes by validating for:
      - at least one character before 'at sign', excluding spaces
      - at least one character after 'at sign', excluding spaces
      - no duplicated at signs
      - domain dots in correct position
    */
    const emailSimpleRegex: RegExp = /^[^\s@]+@[^\s@\.]+(\.[^\s@\.]+)+$/;

    const trimmedEmail: string = email.trim();
    return emailSimpleRegex.test(trimmedEmail.trim()) === true;
  }

  public static combineUrlPaths(path1: string, path2: string): string {
    const path1EndsWithSlash: boolean = path1.endsWith('/');
    const path2StartsWithSlash: boolean = path2.startsWith('/');

    if (path1EndsWithSlash !== path2StartsWithSlash) {
      return `${path1}${path2}`;
    }

    if (path1EndsWithSlash) {
      // both have a slash
      return `${path1.slice(0, path1.length - 1)}${path2}`;
    }

    return `${path1}/${path2}`;
  }

  public static removeTrailingSlash(url: string): string {
    return url.endsWith('/') ? url.slice(0, -1) : url;
  }

  /**
   * Checks if the string represent a domain with path url (with a without protocol)
   * For instance www.domain.com/path or http://www.domain.com/path
   *
   * @param url string to check
   * @returns boolean indication if the string looks like a url with path
   */
  public static isFullPathUrl(url: string): boolean {
    return /^http[s]?:\/\/.+\/.+/i.test(url) || /^([^.\/]+\.)+[^.\/]+\/.+/.test(url);
  }

  public static areEmailsValid(emails: string): boolean {
    let areValid: boolean = true;
    const emailsData: string[] = emails.split(';');

    for (const singleEmail of emailsData) {
      if (this.isValidEmail(singleEmail) === false) {
        areValid = false;
        break;
      }
    }

    return areValid;
  }

  public static normalizeXPath(xpath: string, options: { stripRootHtml?: boolean } = {}): string {
    if (typeof xpath !== 'string' || xpath.trim().length === 0) {
      return xpath;
    }

    if (xpath[0] === '.') {
      // Assume that xpath is correct if it starts with a dot.
      return xpath;
    }

    const rootSymbol: string = '/';
    const htmlRoot: string = '/html';

    if (options.stripRootHtml && xpath.toLowerCase().startsWith(htmlRoot)) {
      return `${rootSymbol}${xpath.substring(htmlRoot.length)}`;
    }

    if (xpath[0] !== rootSymbol) {
      return `${rootSymbol}${rootSymbol}${xpath}`;
    }

    if (xpath[0] === rootSymbol && xpath[1] !== rootSymbol) {
      return `${rootSymbol}${xpath}`;
    }

    return xpath;
  }

  /**
   * produces an array of integers starting with [start], with [length] elements with difference between elements [stride].
   *
   * @param length
   * @param start
   * @param stride
   */
  public static range(length: number, start: number = 0, stride: number = 1): number[] {
    const result: number[] = new Array<number>(length);

    for (let i = 0; i < length; i++) {
      result[i] = start + i * stride;
    }

    return result;
  }

  public static formatBytes(bytes: number, decimals: number = 2): string {
    const absBytes: number = Math.abs(bytes);
    const sign: string = bytes < 0 ? '-' : '';
    const byteStr = (n: number): string => `${sign}${n.toFixed(decimals)}`;

    if (absBytes > GB) {
      return byteStr(absBytes / GB) + ' GB';
    } else if (absBytes > MB) {
      return byteStr(absBytes / MB) + ' MB';
    } else if (absBytes > KB) {
      return byteStr(absBytes / KB) + ' KB';
    }
    return byteStr(absBytes) + ' B';
  }

  public static formatCompactNumber(value: number): string {
    const handleDecimal = (num: number): string => {
      if (num % 1 === 0) {
        return num.toFixed(0);
      }
      return num.toFixed(1);
    };

    if (value >= 1000000000) {
      return handleDecimal(value / 1000000000) + 'B';
    }
    if (value >= 1000000) {
      return handleDecimal(value / 1000000) + 'M';
    }
    if (value >= 1000) {
      return handleDecimal(value / 1000) + 'K';
    }
    return value.toString();
  }

  public static getFileExtension(fileName: string, excludeDot: boolean = false): string {
    const splitFileName: string[] = fileName.split('.');
    if (splitFileName.length < 2) {
      return '';
    }

    const extension: string = splitFileName.pop().toLowerCase();
    return excludeDot ? extension : `.${extension}`;
  }

  public static isValidSubdomain(subdomain: string): boolean {
    return subdomain.length > 0 && subdomainPattern.test(subdomain);
  }

  public static isValidJSON(data: any): data is string {
    if (SharedCommonUtility.isNullish(data)) {
      return false;
    }

    try {
      JSON.parse(data);
    } catch (e) {
      return false;
    }

    return true;
  }

  public static async promisify<T = any>(object: any, fn: Function, ...args: any[]): Promise<T> {
    return new Promise((resolve: any, reject: any) => {
      const callback = (err: any, reply: any): any => {
        if (err) {
          reject(err);
        } else {
          resolve(reply);
        }
      };
      fn.apply(object, [...args, callback]);
    });
  }

  public static freezeDeep<T>(o: T): T {
    if (this.isNullish(o)) {
      return o;
    }

    Object.freeze(o);

    if (Array.isArray(o)) {
      o.forEach((item: unknown): void => {
        this.freezeDeep(item);
      });
      return o;
    }

    Object.getOwnPropertyNames(o).forEach((name: string): void => {
      const property: unknown = o[name];
      if (typeof property === 'object' && !Object.isFrozen(property)) {
        this.freezeDeep(property);
      }
    });

    return o;
  }

  public static isInteger(value: string | number): boolean {
    switch (typeof value) {
      case 'number':
        return Number.isInteger(value);
      case 'string':
        return value.trim() ? Number.isInteger(Number(value)) : false;
      default:
        return false;
    }
  }

  public static isPositiveInteger(value: string | number): boolean {
    if (typeof value === 'number') {
      return Number.isInteger(value) && value > 0;
    }
    return /^[1-9][0-9]*$/.test(value);
  }

  public static getPaginatedItems<T>(items: T[], page: number, pageSize: number): T[] {
    const start: number = (page - 1) * pageSize;
    const end: number = page * pageSize;

    return items.slice(start, end);
  }

  public static getIdProperty(object: Record<string, any> | string): string {
    const handleObject = (): string => {
      if (SharedCommonUtility.notNullish(object?.['_id'])) {
        return String(object['_id']);
      }

      return undefined;
    };

    return typeof object === 'string' ? object : handleObject();
  }

  public static xor(a: boolean, b: boolean): boolean {
    return (a && !b) || (!a && b);
  }

  /**
   * convenience function to use to throw errors inline when something is undefined where you didn't expect it to be
   * example: const user: IUser = userIdToUserMap[userId] ?? SharedCommonUtility.throwError(CodedError.of(...));
   */
  public static throwError(error: any): never {
    throw error;
  }

  public static toArrayIfDefined(value: string): string[] | undefined {
    if (SharedCommonUtility.isNullish(value)) {
      return undefined;
    }
    return [value];
  }

  // This method was ported over here from server/core/shared/utils/common.utility.ts
  public static getProtocolHostPortAndPath(url: string): (string | number)[] {
    let port: number;
    let protocol: string = 'http';
    let pathname: string;

    let fixedUrl: string = url.trim();
    if (url.startsWith('https://') === false && url.startsWith('http://') === false) {
      fixedUrl = `http://${SharedCommonUtility.removeProtocolFromUrl(fixedUrl)}`;
    }
    const sanitizedUrl: URL = new URL(fixedUrl);

    port = parseInt(sanitizedUrl.port, 10);
    if (sanitizedUrl.protocol === 'http:') {
      if (sanitizedUrl.port === '') {
        port = 80;
      }
    } else if (sanitizedUrl.protocol === 'https:') {
      protocol = 'https';
      if (sanitizedUrl.port === '') {
        port = 443;
      }
    }

    pathname = sanitizedUrl.pathname;
    if (pathname.length > 0) {
      pathname = pathname.substr(1);
    }

    return [protocol, sanitizedUrl.hostname, port, pathname];
  }

  // note: this function does not mutate the original object, and short-circuits circular references.
  public static deepOmit(object: any, keysToOmit: string[]): any {
    if (SharedCommonUtility.isNullish(object)) {
      return {};
    }
    const keysToOmitIndex: Dictionary<string> = keyBy(keysToOmit);
    // create map to detect circular references
    const seenObjects: WeakMap<any, boolean> = new WeakMap();
    const clone: any = { ...object };

    return SharedCommonUtility.omitFromObject(clone, keysToOmitIndex, seenObjects);
  }

  private static omitFromObject(obj: any, keysToOmitIndex: Dictionary<string>, seenObjects: WeakMap<any, boolean>): any {
    return transform(obj, function (result: any, value: any, key: string) {
      if (key in keysToOmitIndex) {
        return;
      }
      if (seenObjects.has(value)) {
        return;
      }
      if (isObject(value) && !seenObjects.has(value)) {
        seenObjects.set(value, true);
      }

      result[key] = isObject(value) ? SharedCommonUtility.omitFromObject(value, keysToOmitIndex, seenObjects) : value;
    });
  }
}
