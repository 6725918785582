import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject, combineLatest, EMPTY, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, startWith, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { isEqual } from 'lodash';

import { DsButtonVariants, DsInputStates, Icons, IconStyles } from '@levelaccess/design-system';
import { $user } from '../../../../../shared/constants/user';
import {
  $securityGroup,
  $securityGroups,
  SecurityEntityLevel,
  SecurityGroupOrigin,
} from '../../../../../shared/constants/security-group';
import { CustomValidators } from '../../../services/helpers/form-custom-validators';
import { ErrorMessageService } from '../../../services/error-message.service';
import { UserService } from '../../../services/user.service';
import { TranslateService } from '../../../translate/translate.service';
import { IUserAvatar } from '../../../interfaces/user.interface';
import { ErrorHandlerService } from '../../../services/error-handler.service';
import { IMfaStatusResponse, IUserServerResponse } from '../../../../../shared/interfaces/user.interface';
import { CommonUtility } from '../../../utility/common.utility';
import { AppConfigService } from '../../../services/app-config.service';
import { avatarFile } from '../../../../../shared/constants/multer';
import { LoadErrorHandler } from '../../../services/load-error-handler';
import { SharedCommonUtility } from '../../../../../shared/utils/common.utility';
import { NotificationPosition } from '../../../models/notification.model';
import { NotificationService } from '../../../services/notification.service';
import { SecurityGroupService, SecurityGroupsParams } from '../../../services/security-group.service';
import {
  ISecurityGroup,
  ISecurityGroupsResponse,
  ISecurityGroupWithEntityNames,
} from '../../../../../shared/interfaces/security-group.interface';
import { ITableColumn, ITableConfig, ITableRow, SelectAllEvent } from '../../table/ngb-table/utilities/ngb-table.interface';
import { NgbTableUtilities } from '../../table/ngb-table/utilities/ngb-table.utilities';
import { DEFAULT_ENTRY_LIMITS } from '../../../constants/form.constants';
import { SecurityUserService } from '../../../services/security-user.service';
import { ModalService } from '../../../services/modal.service';
import { IRequiredSecurity } from '../../../../../shared/interfaces/security.interface';
import { RequiredSecurities } from '../../../../../shared/constants/required-securities';
import { CommonConfirmationComponent } from '../../common-confirmation-modal/common-confirmation.component';
import { AngularUtility } from '../../../utility/angular.utility';
import { EapColors } from '../../../shared/eap-colors';
import { BannerType } from './edit-permissions-banner/edit-permissions-banner.component';
import { IUserRole } from '../../../../../shared/interfaces/user-role.interface';
import { UserRoleService } from '../../../services/user-role.service';
import { FormService } from '../../../services/form.service';

export enum UserSecurityGroupSelection {
  groupSelection = 'groupSelection',
}

enum GroupsTableColumns {
  checkbox = 'checkbox',
  workspace = 'workspace',
  digital_property = 'digital_property',
  group = 'group',
}

const ORGANIZATION_ADMINISTRATOR: Readonly<string> = 'Organization administrator';

const isOrgAdmin = (securityGroups: ISecurityGroup[]): boolean => {
  return securityGroups.some((group: ISecurityGroup) => {
    return (
      group[$securityGroup.origin] === SecurityGroupOrigin.predefined && group[$securityGroup.name] === ORGANIZATION_ADMINISTRATOR
    );
  });
};

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss'],
})
export class EditUserComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription;
  private formValidationRequest: Subject<void>;
  private user: IUserServerResponse;

  @ViewChild('workspaceAdminInfo', { read: TemplateRef })
  private workspaceAdminInfoRef: TemplateRef<any>;

  @ViewChild('noGroupsSelectedError', { read: TemplateRef })
  private noGroupsSelectedErrorRef: TemplateRef<any>;

  @Input() public userId: string;
  @Input() public titleId: string;

  public $user: typeof $user;
  public formValidationRequest$: Observable<void>;
  public mfaStatusResponse$: BehaviorSubject<IMfaStatusResponse>;

  public form: UntypedFormGroup;
  public permissionsForm: UntypedFormGroup;
  public tableData: ITableRow[] = [];
  public pageNumber: number = 1;
  public pageSize: number = DEFAULT_ENTRY_LIMITS[1];
  public readonly DEFAULT_ENTRY_LIMITS: readonly number[] = DEFAULT_ENTRY_LIMITS;
  public DsInputStates: typeof DsInputStates = DsInputStates;
  public totalRows$: Observable<number>;

  public availableSecurityGroups$: Observable<ISecurityGroupWithEntityNames[]>;
  public securityGroupsResponse$: Observable<ISecurityGroupsResponse>;
  public orgAdminRequiredSecurity: IRequiredSecurity = RequiredSecurities.UM_Organization_Administration_Create;
  public userSelectedSecurityGroupsIds: Set<string>;
  public selectedSecurityGroupsFromServer: ISecurityGroupsResponse;
  public organizationAdminCheckbox$: Observable<boolean>;
  public selectionChanged$: BehaviorSubject<void>;
  public tableData$: Observable<ITableRow[]>;
  public selectableGroups: ISecurityGroup[];
  public selectablePermissionGroups: ISecurityGroup[];
  public allPageSelected$: Observable<boolean>;
  public showOrgAdminCheckbox$: Observable<boolean>;
  public userRoles$: Observable<IUserRole[]>;
  public displayNameError$: Observable<string>;
  public roleError: string;

  public page$: BehaviorSubject<number>;
  public pageSize$: BehaviorSubject<number>;
  public pageSizes: readonly number[];

  public DsButtonVariants: typeof DsButtonVariants = DsButtonVariants;
  public Icons: typeof Icons = Icons;
  public EapColors: typeof EapColors = EapColors;
  public IconStyles: typeof IconStyles = IconStyles;
  public BannerType: typeof BannerType = BannerType;
  public permissionsBannerRef: TemplateRef<any> = null;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private formService: FormService,
    private element: ElementRef<Element>,
    private userService: UserService,
    private translateService: TranslateService,
    private errorMessageService: ErrorMessageService,
    private router: Router,
    private errorHandlerService: ErrorHandlerService,
    private changeDetector: ChangeDetectorRef,
    private appConfigService: AppConfigService,
    private loadErrorHandler: LoadErrorHandler,
    private notificationService: NotificationService,
    private securityGroupService: SecurityGroupService,
    private securityUserService: SecurityUserService,
    private modalService: ModalService,
    private activatedRoute: ActivatedRoute,
    private userRoleService: UserRoleService,
  ) {
    this.subscriptions = new Subscription();
    this.$user = $user;
    this.formValidationRequest = new Subject<any>();
    this.formValidationRequest$ = this.formValidationRequest.asObservable();
    this.mfaStatusResponse$ = new BehaviorSubject<IMfaStatusResponse>(undefined);
    this.userSelectedSecurityGroupsIds = new Set<string>();

    this.page$ = new BehaviorSubject<number>(1);
    this.pageSize$ = new BehaviorSubject<number>(DEFAULT_ENTRY_LIMITS[1]);
    this.selectableGroups = [];
    this.selectablePermissionGroups = [];
    this.selectionChanged$ = new BehaviorSubject<void>(undefined);

    this.permissionsForm = this.formBuilder.group({
      [$user.groupIds]: new UntypedFormArray([]),
    });
  }

  public createForm(): UntypedFormGroup {
    const formConfig: Record<string, UntypedFormControl | UntypedFormArray | UntypedFormGroup> = {
      [$user.email]: new UntypedFormControl('', {
        validators: [CustomValidators.required, CustomValidators.validateIsEmpty, CustomValidators.validateEmail],
        updateOn: 'change',
      }),
      [$user.displayName]: new UntypedFormControl('', {
        validators: [CustomValidators.required, CustomValidators.validateIsEmpty],
        updateOn: 'change',
      }),
      [$user.firstName]: new UntypedFormControl(''),
      [$user.lastName]: new UntypedFormControl(''),
      [$user.roleId]: new UntypedFormControl('', [CustomValidators.required, CustomValidators.validateIsEmpty]),
      [$user.avatar]: new UntypedFormControl(null),
      [UserSecurityGroupSelection.groupSelection]: new UntypedFormGroup({
        [$user.groupIds]: new UntypedFormArray([]),
        [$user.organizationAdministrator]: new UntypedFormControl(false),
      }),
    };

    return this.formBuilder.group(formConfig);
  }

  public get groupsFormFields(): { groupIds: UntypedFormArray; organizationAdministrator: UntypedFormControl } {
    const securityGroupsFormFields: AbstractControl | null = this.form?.get(UserSecurityGroupSelection.groupSelection);
    return {
      [$user.groupIds]: securityGroupsFormFields?.get($user.groupIds) as UntypedFormArray,
      [$user.organizationAdministrator]: securityGroupsFormFields?.get($user.organizationAdministrator) as UntypedFormControl,
    };
  }

  public get groupsSelectionFormGroup(): UntypedFormGroup {
    return this.form.get(UserSecurityGroupSelection.groupSelection) as UntypedFormGroup;
  }

  private resetFormWithDefaults(): void {
    const avatar: IUserAvatar = {
      src: this.userService.getAvatar(this.user),
      alt: this.translateService.instant('edit_profile_user_avatar_alt_text'),
    };

    this.form.get($user.avatar).setValue(avatar);
    this.form.get($user.email).setValue(this.user[$user.email]);
    this.form.get($user.displayName).setValue(this.user[$user.displayName]);
    this.form.get($user.firstName).setValue(this.user[$user.firstName]);
    this.form.get($user.lastName).setValue(this.user[$user.lastName]);

    if (SharedCommonUtility.notNullish(this.user[$user.roleId])) {
      this.form.get($user.roleId).setValue(this.user[$user.roleId]);
    }

    this.form
      .get(UserSecurityGroupSelection.groupSelection)
      .get($user.organizationAdministrator)
      .patchValue(isOrgAdmin(this.selectedSecurityGroupsFromServer.groups));
    // the form value will be set through userSelectedSecurityGroupsIds + selectionChanged$ observable
    this.form.get(UserSecurityGroupSelection.groupSelection).get($user.groupIds).patchValue([]);
    this.userSelectedSecurityGroupsIds = new Set<string>(
      this.selectedSecurityGroupsFromServer.groups.map((group: ISecurityGroup) => group[$securityGroup._id]),
    );
    this.selectionChanged$.next();

    this.changeDetector.detectChanges();
  }

  private getTableData(securityGroups: ISecurityGroupWithEntityNames[]): ITableRow[] {
    return securityGroups.map((securityGroup: ISecurityGroupWithEntityNames) => {
      const groupId: string = securityGroup[$securityGroup._id];
      return {
        originalData: securityGroup,
        data: {
          [GroupsTableColumns.checkbox]: NgbTableUtilities.checkboxCell({
            checked: this.userSelectedSecurityGroupsIds.has(groupId),
            attributes: {
              'aria-label': this.translateService.instant('select_label', securityGroup[$securityGroup.name]),
            },
            styles: {
              marginTop: '5px',
            },
            listeners: {
              click: ($event: MouseEvent): void => {
                $event.stopPropagation();
                if (this.userSelectedSecurityGroupsIds.has(groupId)) {
                  this.userSelectedSecurityGroupsIds.delete(groupId);
                } else {
                  this.userSelectedSecurityGroupsIds.add(groupId);
                }
                this.selectionChanged$.next();
              },
            },
          }),
          [GroupsTableColumns.workspace]: NgbTableUtilities.htmlCell({
            text: securityGroup.workspaceNames.join(),
          }),
          [GroupsTableColumns.digital_property]: NgbTableUtilities.htmlCell({
            text: securityGroup.digitalPropertyNames.join(),
          }),
          [GroupsTableColumns.group]: NgbTableUtilities.htmlCell({
            text: securityGroup[$securityGroup.name],
          }),
        },
      };
    });
  }

  public get tableConfig(): ITableConfig {
    const columns: Record<string, ITableColumn> = {
      [GroupsTableColumns.checkbox]: {
        translationKey: 'select_all_the_groups',
        selectAllEnabled: true,
        hideLabel: true,
        styles: {
          maxWidth: '3rem',
          width: '2.8rem',
          paddingRight: '0',
          paddingLeft: '0.95rem',
        },
      },
      [GroupsTableColumns.workspace]: {
        translationKey: 'workspace',
      },
      [GroupsTableColumns.digital_property]: {
        translationKey: 'digital_property',
      },
      [GroupsTableColumns.group]: {
        translationKey: 'label_security_group_name',
      },
    };

    return {
      columns,
      caption: this.translateService.instant('security_groups_table'),
      emptyState: {
        title: this.translateService.instant('label_no_security_groups'),
      },
    };
  }

  public onSelectAll(event: SelectAllEvent): void {
    this.selectableGroups.forEach((securityGroup: ISecurityGroup) => {
      if (event.selected) {
        this.userSelectedSecurityGroupsIds.add(securityGroup[$securityGroup._id]);
        return;
      }
      this.userSelectedSecurityGroupsIds.delete(securityGroup[$securityGroup._id]);
    });
    this.selectionChanged$.next();
  }

  private updateMfaStatus(): void {
    const getMfaStatus = this.userService.getMfaStatusForUser(this.userId);

    this.subscriptions.add(
      getMfaStatus.pipe(take(1)).subscribe({
        next: (status: IMfaStatusResponse): void => this.mfaStatusResponse$.next(status),
        error: (error: HttpErrorResponse): void =>
          this.loadErrorHandler.handleError('mfa_failed_to_get_status', error, EditUserComponent.name),
      }),
    );
  }

  private onDisableMfaSuccess(): void {
    const message: string = this.translateService.instant('mfa_disabled_successfully');
    this.notificationService.success(message, NotificationPosition.Toast);
    this.updateMfaStatus();
  }

  private async onEditUserSuccess(): Promise<void> {
    this.subscriptions.add(
      of(this.userId === this.userService.getStoredUserId())
        .pipe(
          switchMap((shouldReloadCurrentUser: boolean): Observable<void> => {
            if (shouldReloadCurrentUser) {
              return this.userService.getMeProfile().pipe(switchMap(() => of(void 0)));
            }
            return of(void 0);
          }),
          tap((): void => {
            this.notificationService.success(
              this.translateService.instant('edit_security_user_success'),
              NotificationPosition.Toast,
              true,
            );
            this.navigateToParentRoute();
          }),
        )
        .subscribe(),
    );
  }

  private onEditUserError(error: HttpErrorResponse, permissionsUpdate: boolean = false): void {
    if (!permissionsUpdate) {
      this.form.enable();
      this.form.get($user.email).disable();
    }

    this.loadErrorHandler.handleError('edit_security_user_failure', error, EditUserComponent.name);
  }

  private getFormDataWithoutAvatar(): FormData {
    return CommonUtility.createFormData({
      [$user.displayName]: this.form.get($user.displayName).value,
      [$user.firstName]: this.form.get($user.firstName).value,
      [$user.lastName]: this.form.get($user.lastName).value,
      [$user.groupIds]: Array.from(this.userSelectedSecurityGroupsIds),
      [$user.roleId]: this.form.get($user.roleId).value,
    });
  }

  private confirmGroupRemoveModal(user: IUserServerResponse, userProfile: FormData): Observable<void> {
    const modal: CommonConfirmationComponent = this.modalService.open(CommonConfirmationComponent).instance;
    modal.onCreate({
      header: 'remove_user',
      messages: [this.translateService.instant('remove_user_are_you_sure', user[$user.displayName])],
      labelAction: 'remove_user',
      labelClose: 'form_cancel',
      description: this.translateService.instant('remove_user_confirmation_description', user[$user.displayName]),
    });
    return modal.container.onClose$.pipe(
      take(1),
      switchMap((): Observable<void> => {
        if (modal.confirmed) {
          return this.securityUserService.updateUserProfile(this.userId, userProfile);
        }
        this.form.enable();
        return EMPTY;
      }),
    );
  }

  public onPermissionsSubmit(event: Event): void {
    event.preventDefault();

    const selectablePermissionGroupIds: string[] = this.selectablePermissionGroups.map(
      (group: ISecurityGroup): string => group[$securityGroup._id],
    );
    const { groupIds: selectedPermissionGroupIds }: { groupIds: string[] } = this.permissionsForm.value;

    const hasSelectablePermissionsSelected: boolean = selectedPermissionGroupIds.some((groupId: string) =>
      selectablePermissionGroupIds.includes(groupId),
    );
    if (!hasSelectablePermissionsSelected) {
      this.permissionsBannerRef = this.noGroupsSelectedErrorRef;
      return;
    }

    this.subscriptions.add(
      this.securityUserService.updateUserSecurityGroups(this.userId, this.permissionsForm.value).subscribe({
        next: this.onEditUserSuccess.bind(this),
        error: this.onEditUserError.bind(this, true),
      }),
    );
  }

  public navigateToParentRoute(): void {
    this.router
      .navigate(['../..'], { relativeTo: this.activatedRoute })
      .catch(this.errorHandlerService.handleRoutingError.bind(this.errorHandlerService));
  }

  public onOrgAdminPermissionsLoaded(groups: ISecurityGroupWithEntityNames[]): void {
    if (groups.length === 0) {
      this.permissionsBannerRef = this.workspaceAdminInfoRef;
    } else {
      this.selectablePermissionGroups.push(...groups);
    }
  }

  public onWorkspacePermissionsLoaded(groups: ISecurityGroupWithEntityNames[]): void {
    this.selectablePermissionGroups.push(...groups);
  }

  public onDigitalPropertyPermissionsLoaded(groups: ISecurityGroupWithEntityNames[]): void {
    this.selectablePermissionGroups.push(...groups);
  }

  public onPermissionsBannerClose(): void {
    this.permissionsBannerRef = null;
  }

  public onSubmit(event: Event): void {
    this.roleError = '';
    event.preventDefault();
    this.formValidationRequest.next();

    if (!this.form.valid) {
      if (this.form.get($user.roleId).errors) {
        this.roleError = this.formService.getErrorMessageForField(
          this.form.get($user.roleId),
          $user.roleId,
          '',
          this.translateService.instant('manage_users_role_label'),
        );
      }

      this.errorMessageService.setFocusOnFirstError(this.element.nativeElement);
      return;
    }
    this.form.disable();

    const isAvatarUpdated: boolean =
      this.form.get($user.avatar).value !== null && typeof this.form.get($user.avatar).value.blob !== 'undefined';

    const userProfile: FormData = this.getFormDataWithoutAvatar();
    if (isAvatarUpdated) {
      userProfile.append(avatarFile, this.form.get($user.avatar).value.blob, 'placeholder');
    }

    let updateRequestCall: Observable<void>;

    const securityGroupsFormFields: AbstractControl | null = this.form.get(UserSecurityGroupSelection.groupSelection);
    const deleteUserFromAllGroups: boolean =
      !securityGroupsFormFields?.get($user.organizationAdministrator).value && this.userSelectedSecurityGroupsIds.size === 0;

    if (deleteUserFromAllGroups) {
      updateRequestCall = this.confirmGroupRemoveModal(this.user, userProfile);
    } else {
      updateRequestCall = this.securityUserService.updateUserProfile(this.userId, userProfile);
    }

    this.subscriptions.add(
      updateRequestCall.subscribe({
        next: this.onEditUserSuccess.bind(this),
        error: this.onEditUserError.bind(this),
      }),
    );
  }

  public onDisableMfa(): void {
    this.subscriptions.add(
      this.userService.disableMfaForUser(this.userId).subscribe({
        next: this.onDisableMfaSuccess.bind(this),
        error: (error: HttpErrorResponse): void =>
          this.loadErrorHandler.handleError('mfa_failed_to_disable', error, EditUserComponent.name),
      }),
    );
  }

  public onPageChange(page: number): void {
    this.page$.next(page);
  }

  public onPageSizeChange(pageSize: number): void {
    this.pageSize$.next(pageSize);
  }

  private subscribeToSelectionChange(): void {
    this.subscriptions.add(
      this.selectionChanged$.subscribe({
        next: (): void => {
          const userGroupsFormArray: UntypedFormArray = this.groupsFormFields[$user.groupIds];
          userGroupsFormArray.clear();

          this.userSelectedSecurityGroupsIds.forEach((id: string) => {
            userGroupsFormArray.push(
              new UntypedFormControl({
                [$securityGroup._id]: id,
              }),
            );
          });
        },
      }),
    );
  }

  private async loadOneTimeCompleteObservables(): Promise<unknown[]> {
    this.form = this.createForm();
    this.userRoles$ = this.getUserRoles$();
    this.buildErrorObservables();

    const user$: Observable<IUserServerResponse> = this.userService.getUserById(this.userId).pipe(
      tap((user: IUserServerResponse): void => {
        this.user = user;
      }),
      catchError((error: HttpErrorResponse): Observable<undefined> => {
        this.loadErrorHandler.handleError('error_while_getting_admin_user', error, EditUserComponent.name);
        return of(undefined);
      }),
    );

    const baseParams: { origin: SecurityGroupOrigin } = {
      origin: SecurityGroupOrigin.predefined,
    };
    const selectedSecurityGroups$: Observable<ISecurityGroupsResponse> = this.securityGroupService
      .findAllSecurityGroupsByUserIdAndTenantId(this.userId, {
        ...(this.appConfigService.isTenantEnabled()
          ? {
              ...baseParams,
              includeStaffGroups: true,
            }
          : { entityLevel: SecurityEntityLevel.admin }),
      })
      .pipe(
        tap((securityGroups: ISecurityGroupsResponse) => {
          this.selectedSecurityGroupsFromServer = securityGroups;

          const permissionsFormArray: UntypedFormArray = this.permissionsForm.get($user.groupIds) as UntypedFormArray;

          securityGroups.groups.forEach((group: ISecurityGroup): void =>
            permissionsFormArray.push(this.formBuilder.control(group[$securityGroup._id])),
          );
        }),
        catchError((error: HttpErrorResponse): Observable<undefined> => {
          this.loadErrorHandler.handleError('error_failed_to_load_security_groups', error, EditUserComponent.name);
          return of(undefined);
        }),
      );

    return Promise.all([selectedSecurityGroups$.pipe(take(1)).toPromise(), user$.pipe(take(1)).toPromise()]);
  }

  private buildTableObservables(): void {
    this.organizationAdminCheckbox$ = this.groupsFormFields.organizationAdministrator?.valueChanges.pipe(
      filter(SharedCommonUtility.notNullish),
      startWith(this.groupsFormFields.organizationAdministrator?.value as boolean),
      AngularUtility.shareRef<boolean>(),
    );

    this.securityGroupsResponse$ = combineLatest([
      this.page$,
      this.pageSize$,
      this.organizationAdminCheckbox$,
      this.showOrgAdminCheckbox$,
    ]).pipe(
      filter(SharedCommonUtility.elementsNotNullish),
      switchMap(
        (args: [number, number, boolean, boolean]): Observable<ISecurityGroupsResponse<ISecurityGroupWithEntityNames>> => {
          const [page, pageSize, isOrganizationAdminSelected, usePermissionsRedesign] = args;
          if (usePermissionsRedesign) {
            return of({ [$securityGroups.groups]: [], [$securityGroups._total]: 0 });
          }

          const params: SecurityGroupsParams = {
            skip: Number(this.activatedRoute.snapshot.queryParams['skip'] || page),
            limit: Number(this.activatedRoute.snapshot.queryParams['limit'] || pageSize),
            ...(isOrganizationAdminSelected
              ? {
                  // retrieves organization administrator group for a tenant
                  includeStaffGroups: false,
                  entityLevel: SecurityEntityLevel.tenant,
                  origin: SecurityGroupOrigin.predefined,
                }
              : { includeStaffGroups: false, skipOrganizationAdmin: true }),
          };
          return this.securityGroupService.groupsWithEntityNames(params).pipe(
            tap((loadedGroups: ISecurityGroupsResponse<ISecurityGroupWithEntityNames>) => {
              this.selectableGroups = loadedGroups.groups;
              if (SharedCommonUtility.isNullishOrEmpty(loadedGroups?.[$securityGroups.groups])) {
                this.notificationService.error(this.translateService.instant('loaded_groups_empty'), NotificationPosition.Toast);
              }
              this.selectionChanged$.next();
            }),
          );
        },
      ),
      AngularUtility.shareRef(),
      catchError(
        this.loadErrorHandler.handleErrorBindable.bind(
          this.loadErrorHandler,
          'error_failed_to_load_security_groups',
          EditUserComponent.name,
        ),
      ),
    );

    this.allPageSelected$ = this.selectionChanged$.pipe(
      switchMap((): Observable<boolean> => {
        const groupIds: string[] = this.selectableGroups.map((group: ISecurityGroup): string => group[$securityGroup._id]);

        const allSelected: boolean =
          groupIds.length > 0 && groupIds.every((id: string): boolean => this.userSelectedSecurityGroupsIds.has(id));

        return of(allSelected);
      }),
    );

    this.availableSecurityGroups$ = this.securityGroupsResponse$.pipe(
      filter((value: ISecurityGroupsResponse): boolean => SharedCommonUtility.notNullish(value)),
      map((data: ISecurityGroupsResponse) => data[$securityGroups.groups]),
    );

    this.totalRows$ = this.securityGroupsResponse$.pipe(
      filter((value: ISecurityGroupsResponse): boolean => SharedCommonUtility.notNullish(value)),
      map((data: ISecurityGroupsResponse): number => {
        return data[$securityGroups._total];
      }),
    );

    this.tableData$ = combineLatest([this.availableSecurityGroups$, this.selectionChanged$]).pipe(
      map(([securityGroups, _]: [ISecurityGroupWithEntityNames[], void]): ITableRow[] => {
        return this.getTableData(securityGroups);
      }),
    );
  }

  private subscribeGetSelectedSecurityGroups(): void {
    this.subscriptions.add(
      this.availableSecurityGroups$
        .pipe(
          withLatestFrom(this.organizationAdminCheckbox$, this.showOrgAdminCheckbox$),
          distinctUntilChanged(isEqual),
          tap(
            ([availableSecurityGroups, orgAdminCheckbox, usePermissionsRedesign]: [
              ISecurityGroupWithEntityNames[],
              boolean,
              boolean,
            ]) => {
              if (orgAdminCheckbox && !usePermissionsRedesign) {
                this.userSelectedSecurityGroupsIds = new Set<string>(
                  availableSecurityGroups.map((group: ISecurityGroup) => group[$securityGroup._id]),
                );
              } else {
                this.userSelectedSecurityGroupsIds = new Set<string>(
                  this.selectedSecurityGroupsFromServer[$securityGroups.groups]
                    .filter((group: ISecurityGroup) => {
                      return (
                        usePermissionsRedesign ||
                        group[$securityGroup.origin] !== SecurityGroupOrigin.predefined ||
                        group[$securityGroup.name] !== ORGANIZATION_ADMINISTRATOR
                      );
                    })
                    .map((value: ISecurityGroup) => value[$securityGroup._id]),
                );
              }
              this.selectionChanged$.next();
            },
          ),
        )
        .subscribe(),
    );
  }

  private buildErrorObservables(): void {
    this.displayNameError$ = this.formService
      .buildFormControlErrorMessage$(
        this.form.get($user.displayName),
        this.translateService.instant('manage_users_display_name_label'),
      )
      .pipe(AngularUtility.shareRef());
  }

  private getUserRoles$(): Observable<IUserRole[]> {
    return this.userRoleService
      .getUserRoles()
      .pipe(
        catchError(
          this.loadErrorHandler.handleErrorObservable.bind(
            this.loadErrorHandler,
            'error_loading_user_roles',
            EditUserComponent.name,
          ),
        ),
      );
  }

  public async ngOnInit(): Promise<void> {
    this.showOrgAdminCheckbox$ = of(this.appConfigService.isTenantEnabled());

    const initialLoad: unknown[] = await this.loadOneTimeCompleteObservables();
    if (!SharedCommonUtility.elementsNotNullish(initialLoad)) {
      // cannot load properly so just short circuit
      return;
    }

    this.resetFormWithDefaults();

    this.buildTableObservables();

    this.updateMfaStatus();
    this.subscribeGetSelectedSecurityGroups();
    this.subscribeToSelectionChange();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
