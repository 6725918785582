import { FeatureFlagCollection } from '../interfaces/feature-flag.interface';

export const E2eFeatureFlagMock: Partial<Record<FeatureFlagCollection, boolean>> = Object.freeze({
  [FeatureFlagCollection.userFlows]: true,
  [FeatureFlagCollection.userFlowsGuided]: true,
  [FeatureFlagCollection.ampTenantApiKey]: true,
  [FeatureFlagCollection.asyncReportProgramDash]: true,
  [FeatureFlagCollection.tenantRiskScore]: true,
  [FeatureFlagCollection.wsRiskScore]: true,
  [FeatureFlagCollection.dpRiskScore]: true,
  [FeatureFlagCollection.govDashWebsiteAppsNewSection]: true,
  [FeatureFlagCollection.riskChartFrequency]: true,
  [FeatureFlagCollection.scimFunctionality]: true,
  [FeatureFlagCollection.scansListRedesign]: true,
  [FeatureFlagCollection.scansRevampInPopup]: true,
  [FeatureFlagCollection.requestManualEvaluation]: true,
  [FeatureFlagCollection.filteringUsers]: true,
  [FeatureFlagCollection.removedStepper]: true,
  [FeatureFlagCollection.mostRecentWorkspaces]: true,
  [FeatureFlagCollection.evaluationStatus]: true,
  [FeatureFlagCollection.pushManualRequestAudit]: true,
  [FeatureFlagCollection.scanBanner]: true,
  [FeatureFlagCollection.manualEvaluationConformanceTab]: true,
  [FeatureFlagCollection.updateTaskSlideout]: true,
  [FeatureFlagCollection.navSupportPortalUx]: true,
});
