import { standardNameAndLevel } from './scanning';

export enum scanCreateRequest {
  accessibility = 'accessibility',
  colorBlindEmulation = 'colorBlindEmulation',
  conformanceLevel = 'conformanceLevel',
  crawlDeepLevel = 'crawlDeepLevel',
  createdAtTimezoneOffset = 'createdAtTimezoneOffset',
  customHeight = 'customHeight',
  customWidth = 'customWidth',
  discoverScannableDocuments = 'discoverScannableDocuments',
  essentialBestPractice = 'essentialBestPractice',
  excludeUrls = 'excludeUrls',
  excludeUrlOperations = 'excludeUrlOperations',
  headers = 'headers',
  includeUrls = 'includeUrls',
  importLinksFiles = 'importLinksFiles',
  languageForScanning = 'languageForScanning',
  limitCrawlingPagesTo = 'limitCrawlingPagesTo',
  listOfUrls = 'listOfUrls',
  repeatEvery = 'repeatEvery',
  saveScanOptionsName = 'saveScanOptionsName',
  scanAuthenticationType = 'scanAuthenticationType',
  scanAuthenticationUserName = 'scanAuthenticationUserName',
  scanAuthenticationPassword = 'scanAuthenticationPassword',
  scanMonthlyOnDay = 'scanMonthlyOnDay',
  scanningType = 'scanningType',
  scanRecurrenceEvery = 'scanRecurrenceEvery',
  scanRecurrenceSelectedDay = 'scanRecurrenceSelectedDay',
  scanRecurrenceType = 'scanRecurrenceType',
  secureWebAuthentication = 'secureWebAuthentication',
  selectedScanOptionsId = 'selectedScanOptionsId',
  sendEmail = 'sendEmail',
  skipHashes = 'skipHashes',
  skipQueryParams = 'skipQueryParams',
  startScanAtDate = 'startScanAtDate',
  stopScanAtDate = 'stopScanAtDate',
  swaLoginPageURL = 'swaLoginPageURL',
  swaUserName = 'swaUserName',
  swaUserNameFieldId = 'swaUserNameFieldId',
  swaPassword = 'swaPassword',
  swaPasswordFieldId = 'swaPasswordFieldId',
  swaSubmitFieldId = 'swaSubmitFieldId',
  swaSubmitUsernameFieldId = 'swaSubmitUsernameFieldId',
  swaSubmitSuccessId = 'swaSubmitSuccessId',
  title = 'title',
  tag = 'tag',
  unlimitedCrawlDeepLevel = 'unlimitedCrawlDeepLevel',
  unlimitedCrawlingPagesTo = 'unlimitedCrawlingPagesTo',
  url = 'url',
  userAgent = 'userAgent',
  viewport = 'viewport',
  viewportType = 'viewportType',
  automatedUserFlowId = 'automatedUserFlowId',
  handlePopupsSelector = 'handlePopupsSelector',
  includeIframes = 'includeIframes',
  preScanFlowId = 'preScanFlowId',
}

export const $scanDefaultConformanceLevel: standardNameAndLevel = standardNameAndLevel.WCAG_v21_AA;
