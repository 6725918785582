export enum $securityRole {
  _id = '_id',
  code = 'code',
  name = 'name',
  description = 'description',
  origin = 'origin',
  level = 'level',
  functionalActions = 'functionalActions',
  isStaffRole = 'isStaffRole',
  isServiceRole = 'isServiceRole',
}

export enum $securityRoles {
  roles = 'roles',
  _total = '_total',
}

export enum $securitySet {
  byEntityLevels = 'byEntityLevels',
  byFunctionalActions = 'byFunctionalActions',
}

export enum SecurityRoleOrigin {
  custom = 'custom',
  predefined = 'predefined',
}

export enum SecureAction {
  create = 'create',
  read = 'read',
  update = 'update',
  delete = 'delete',
  download = 'download',
}

export enum FunctionalArea {
  application = 'application',

  pdf = 'pdf',
  pdf_testing = 'pdf_testing',
  pdf_remediation = 'pdf_remediation',
  pdf_administration = 'pdf_administration',

  governance_and_reporting = 'governance_and_reporting',
  gr_dashboard = 'gr_dashboard',
  gr_governance = 'gr_governance',
  gr_monitoring = 'gr_monitoring',
  gr_organization_dashboard = 'gr_organization_dashboard',
  summary_snapshot_api = 'summary_snapshot_api',

  design_evaluations = 'design_evaluations',
  de_administration = 'de_administration',
  de_results = 'de_results',

  digital_properties = 'digital_properties',

  tools_and_integrations = 'tools_and_integrations',
  ti_tools_and_extensions = 'ti_tools_and_extensions',
  ti_issue_tracking_basic = 'ti_issue_tracking_basic',
  ti_issue_tracking_administration = 'ti_issue_tracking_administration',
  ti_issue_tracking_configuration = 'ti_issue_tracking_configuration',
  ti_issue_tracking_configuration_additional_fields = 'ti_issue_tracking_configuration_additional_fields',
  ti_scim_config = 'ti_scim_config',

  sdk = 'sdk',

  api = 'api',
  api_keys = 'api_keys',
  api_general_apis = 'api_general_apis',
  api_automated_scans = 'api_automated_scans',
  api_manual_evaluations = 'api_manual_evaluations',
  api_tenants = 'api_tenants',
  api_user_flows = 'api_user_flows',
  api_tenant_admin = 'api_tenant_admin',
  api_pages = 'api_pages',
  api_scim = 'api_scim',

  translations = 'translations',

  evaluation_rules = 'evaluation_rules',

  master_library = 'master_library',

  scan_tags = 'scan_tags',
  active_scans = 'active_scans',

  project_management = 'project_management',
  pm_projects = 'pm_projects',
  pm_tasks = 'pm_tasks',
  pm_task_comments = 'pm_task_comments',
  pm_task_admin = 'pm_task_admin',

  automated_flows = 'automated_flows',
  pages = 'pages',

  manual_testing = 'manual_testing',
  mt_manual_evaluations = 'mt_manual_evaluations',
  mt_manual_audits = 'mt_manual_audits',
  mt_comments = 'mt_comments',
  mt_comments_admin = 'mt_comments_admin',

  documents = 'documents',

  automated_testing = 'automated_testing',
  at_scans = 'at_scans',
  at_run_automated_scans = 'at_run_automated_scans',

  component_matching = 'component_matching',

  groups = 'groups',
  user_groups = 'user_groups',
  staff_groups = 'staff_groups',

  tenant_switcher = 'tenant_switcher',

  /**
   * @deprecated Deprecated functional area, use `api` instead. TODO: This will be removed in EAP-21371.
   */
  browser_extension = 'browser_extension',
  /**
   * @deprecated Deprecated functional area, use `api_automated_scans` instead. TODO: This will be removed in EAP-21371.
   */
  be_automated_test = 'be_automated_test',
  /**
   * @deprecated Deprecated functional area, use `api_automated_scans` instead. TODO: This will be removed in EAP-21371.
   */
  be_manual_test = 'be_manual_test',
  /**
   * @deprecated Deprecated functional area, use `api_user_flows` instead. TODO: This will be removed in EAP-21371.
   */
  be_user_flows_api = 'be_user_flows_api',

  invalid = 'invalid',

  global_users = 'global_users',
  gu_global_users = 'gu_global_users',
  gu_all_tenant_admin = 'gu_all_tenant_admin',

  notifications = 'notifications',

  user_management = 'user_management',
  um_user_administration = 'um_user_administration',
  um_organization_administration = 'um_organization_administration',

  tenants = 'tenants',

  workspaces = 'workspaces',

  academy = 'academy',

  service = 'service',
  service_support = 'service_support',
  validation_testing = 'validation_testing',
  ai_chat = 'ai_chat',

  webhook = 'webhook',

  preprod_testing = 'preprod_testing',
  preprod_testing_env_settings = 'preprod_testing_env_settings',
  preprod_testing_reporting = 'preprod_testing_reporting',
  preprod_testing_scan_results = 'preprod_testing_scan_results',

  tenant_rule_libraries = 'tenant_rule_libraries',
  trl_custom_rule_library_management = 'trl_custom_rule_library_management',

  organization_portfolio = 'organization_portfolio',
  request_manual_evaluation = 'request_manual_evaluation',

  userway = 'userway',
  userway_integration = 'userway_integration',

  severities = 'severities',
  custom_severities = 'custom_severities',
}
