import { DsModalColorVariant, Icons } from '@levelaccess/design-system';

import { IRequiredSecurity } from '../../../../shared/interfaces/security.interface';
import { SharedCommonUtility } from '../../../../shared/utils/common.utility';
import { RequiredSecurities } from '../../../../shared/constants/required-securities';
import { Api } from '../../../../shared/constants/api';
import { FeatureFlagCollection } from '../../../../shared/interfaces/feature-flag.interface';
import { SecurityUtility } from '../../../../shared/utils/security.utility';
import { IUserServerResponse } from '../../../../shared/interfaces/user.interface';
import { PackageNames } from '../../../../shared/constants/packaging';
import { ExternalLinkService } from '../external-link.service';

export enum NavigationItem {
  organization_dashboard = 'organization_dashboard',
  organization_portfolio = 'organization_portfolio',

  program_dashboard = 'program_dashboard',

  organization_settings = 'organization_settings',
  organization_settings_users = 'organization_settings_users',
  organization_settings_all_users = 'organization_settings_all_users',
  organization_settings_unassigned_users = 'organization_settings_unassigned_users',
  organization_settings_user_groups = 'organization_settings_user_groups',
  organization_settings_staff_groups = 'organization_settings_staff_groups',
  organization_settings_rule_libraries = 'organization_settings_rule_libraries',
  organization_settings_severities = 'organization_settings_severities',

  evaluations = 'evaluations',
  manual_evaluations = 'manual_evaluations',
  design_evaluations = 'design_evaluations',

  tools_and_integrations = 'tools_and_integrations',
  issue_tracking = 'issue_tracking',
  tools_and_extensions = 'tools_and_extensions',
  sdks = 'sdks',
  api = 'api',
  webhooks = 'webhooks',
  scim = 'scim',
  userway = 'userway',

  administration = 'administration',
  administration_tenants = 'administration_tenants',
  administration_organizations = 'administration_organizations',
  administration_my_organizations = 'administration_my_organizations',
  administration_all_organizations = 'administration_all_organizations',
  administration_users = 'administration_users',
  administration_global_users = 'administration_global_users',
  administration_internal_users = 'administration_internal_users',
  administration_admin_groups = 'administration_admin_groups',

  internal_use_only = 'internal_use_only',
  master_library = 'master_library',
  active_scans = 'active_scans',
  translations = 'translations',

  workspace = 'workspace',
  workspace_dashboard = 'workspace_dashboard',
  websites_and_apps = 'websites_and_apps',
  websites_and_apps_portfolio = 'websites_and_apps_portfolio',
  websites_and_apps_overview = 'websites_and_apps_overview',
  websites_and_apps_monitoring = 'websites_and_apps_monitoring',
  websites_and_apps_scans = 'websites_and_apps_scans',
  websites_and_apps_mobile_scans = 'websites_and_apps_mobile_scans',
  websites_and_apps_mobile_scans_page_scans = 'websites_and_apps_mobile_scans_page_scans',
  websites_and_apps_scans_page_scans = 'websites_and_apps_scans_page_scans',
  websites_and_apps_scans_components = 'websites_and_apps_scans_components',
  websites_and_apps_scans_user_flows = 'websites_and_apps_scans_user_flows',
  websites_and_apps_scan_rules = 'websites_and_apps_scan_rules',
  websites_and_apps_scan_tags = 'websites_and_apps_scan_tags',
  websites_and_apps_pages = 'websites_and_apps_pages',
  websites_and_apps_manual_evaluations = 'websites_and_apps_manual_evaluations',
  websites_and_apps_manual_evaluations_table = 'websites_and_apps_manual_evaluations_table',
  websites_and_apps_manual_evaluations_requests = 'websites_and_apps_manual_evaluations_requests',
  websites_and_apps_settings = 'websites_and_apps_settings',
  websites_and_apps_environments = 'websites_and_apps_environments',
  workspace_design_evaluations = 'workspace_design_evaluations',
  pdf = 'pdf',
  files = 'files',

  profile = 'profile',
  logout = 'logout',

  projects_and_tasks = 'projects_and_tasks',
  projects = 'projects',
  my_tasks = 'my_tasks',

  help = 'help',
  old_help = 'old_help',
  help_submit_ticket = 'help_submit_ticket',
  help_search_knowledge = 'help_search_knowledge',
  help_product_support = 'help_product_support',
  help_help_center = 'help_help_center',
  help_roadmap = 'help_roadmap',

  service_request = 'service_request',
  service_request_email_consultation = 'service_request_email_consultation',
  service_request_manual_evaluation = 'service_request_manual_evaluation',
  service_request_validation_testing = 'service_request_validation_testing',
  service_request_view_all_services = 'service_request_view_all_services',

  notifications = 'notifications',
  academy = 'academy',
  workspace_switcher = 'workspace_switcher',
  website_app_selector = 'website_app_selector',
}

export interface INavMenuItem {
  id: NavigationItem;
  label: string;
  isAvailable?: boolean;
  isAvailableFn?: (data: { user: IUserServerResponse; packageName: PackageNames }) => boolean;
  displayedOutsideOfNavbar?: boolean;
  isActive?: boolean;
  navTitle?: string;
  isHidden?: boolean;
  overrideRouterLink?: string;
  routerLink?: string;
  externalLink?: string;
  externalLinkFn?: (externalLinkService: ExternalLinkService) => string;
  requiredSecurity?: IRequiredSecurity;
  children?: INavMenuItem[];
  parent?: INavMenuItem;
  iconName?: Icons;
  featureFlag?: string;
  tenantlessOnly?: boolean;
  tenantOnly?: boolean;
  mobileOnly?: boolean;
  mobileModalColorVariant?: DsModalColorVariant;
  restrictByWorkspace?: boolean;
  filters?: INavMenuItemFilter[];
}

export interface INavItemFilterArgs {
  isWebDp?: boolean;
  isMobileDp?: boolean;
}

export interface IWorkspacePermission {
  activeMenuItem: INavMenuItem;
  workspacesMenuItem: INavMenuItem;
  workspaceId: string;
  digitalPropertyId: string;
  hasAccess?: boolean;
}

export type INavMenuItemFilter = (args: INavItemFilterArgs) => boolean;

export function webDpFilter(args: INavItemFilterArgs): boolean {
  return args.isWebDp;
}

export function mobileDpFilter(args: INavItemFilterArgs): boolean {
  return args.isMobileDp;
}

export const NavigationRoutingTree: INavMenuItem[] = SharedCommonUtility.freezeDeep<INavMenuItem[]>([
  {
    id: NavigationItem.evaluations,
    label: 'evaluations_dropdown_menu_item_label',
    iconName: Icons.UniversalAccess,
    children: [
      {
        id: NavigationItem.manual_evaluations,
        label: 'manual_evaluations_navigation_item_label',
        requiredSecurity: RequiredSecurities.MT_Manual_Audits_Read,
        routerLink: `/${Api.admin}/${Api.manual_audit}`,
      },
      {
        id: NavigationItem.design_evaluations,
        label: 'label_design_review_title',
        requiredSecurity: RequiredSecurities.DE_Administration_Read,
        routerLink: `/${Api.designReviews}`,
      },
    ],
    navTitle: 'accessibility_evaluations',
  },
  {
    id: NavigationItem.organization_settings,
    label: 'organization_settings_dropdown_menu_item_label',
    navTitle: 'organization_settings_dropdown_menu_item_label',
    iconName: Icons.Gear,
    children: [
      {
        id: NavigationItem.organization_settings_users,
        label: 'users',
        requiredSecurity: RequiredSecurities.UM_User_Administration_Read_No_Entity_Level,
        tenantOnly: true,
        children: [
          {
            id: NavigationItem.organization_settings_all_users,
            label: 'users',
            routerLink: `/${Api.admin}/${Api.userManagement}/${Api.users}`,
            requiredSecurity: RequiredSecurities.UM_User_Administration_Read_No_Entity_Level,
            tenantOnly: true,
            displayedOutsideOfNavbar: true,
          },
          {
            id: NavigationItem.organization_settings_unassigned_users,
            label: 'unassigned_users',
            routerLink: `/${Api.admin}/${Api.userManagement}/${Api.unassigned_users}`,
            requiredSecurity: RequiredSecurities.UM_User_Administration_Read_No_Entity_Level,
            tenantOnly: true,
            displayedOutsideOfNavbar: true,
            featureFlag: FeatureFlagCollection.scimFunctionality,
          },
        ],
      },
      {
        id: NavigationItem.organization_settings_user_groups,
        label: 'user_groups',
        requiredSecurity: RequiredSecurities.User_Groups_Read,
        routerLink: `/${Api.admin}/${Api.userGroups}`,
        tenantOnly: true,
      },
      {
        id: NavigationItem.organization_settings_staff_groups,
        label: 'staff_groups',
        requiredSecurity: RequiredSecurities.Staff_Groups_Read,
        routerLink: `/${Api.admin}/${Api.staffGroups}`,
        tenantOnly: true,
      },
      {
        id: NavigationItem.organization_settings_rule_libraries,
        label: 'rule_libraries',
        requiredSecurity: RequiredSecurities.Tenant_Rule_Library_Read,
        routerLink: `/${Api.custom_audit_rules}`,
        tenantOnly: true,
        featureFlag: FeatureFlagCollection.customRulesAndSeverity,
      },
      {
        id: NavigationItem.organization_settings_severities,
        label: 'severities',
        requiredSecurity: RequiredSecurities.CustomSeverities_Read,
        routerLink: `/${Api.custom_severities}`,
        tenantOnly: true,
        featureFlag: FeatureFlagCollection.customSeverities,
      },
    ],
  },
  {
    id: NavigationItem.tools_and_integrations,
    label: 'tools_and_integrations_navigation_item_label',
    iconName: Icons.Wrench,
    children: [
      {
        id: NavigationItem.userway,
        label: 'userway',
        routerLink: `/${Api.integrations}/${Api.userway}`,
        requiredSecurity: RequiredSecurities.UserWay_Integration_Read,
      },
      {
        id: NavigationItem.issue_tracking,
        label: 'issue_tracking_navigation_item_label',
        routerLink: `/${Api.integrations}/${Api.issue_tracking}`,
        requiredSecurity: RequiredSecurities.TI_Issue_Tracking_Basic_Read,
      },
      {
        id: NavigationItem.tools_and_extensions,
        label: 'tools_and_extensions_navigation_item_label',
        routerLink: `/${Api.integrations}/${Api.test_automation}`,
        requiredSecurity: RequiredSecurities.TI_Tools_Extensions_Read,
      },
      {
        id: NavigationItem.sdks,
        label: 'sdks_navigation_item_label',
        routerLink: `/${Api.integrations}/${Api.testAutomationSdks}`,
        requiredSecurity: RequiredSecurities.SDK_Read,
        featureFlag: FeatureFlagCollection.continuumIntegration,
      },
      {
        id: NavigationItem.api,
        label: 'api_navigation_item_label',
        routerLink: `/${Api.integrations}/${Api.api}`,
        requiredSecurity: RequiredSecurities.API_Keys_Read,
      },
      {
        id: NavigationItem.webhooks,
        label: 'webhooks_navigation_item_label',
        routerLink: `/${Api.integrations}/${Api.webhooks}`,
        requiredSecurity: RequiredSecurities.Webhook_Read,
      },
      {
        id: NavigationItem.scim,
        label: 'scim_navigation_item_label',
        routerLink: `/${Api.integrations}/${Api.scim}`,
        requiredSecurity: RequiredSecurities.TI_SCIM_Config_Create,
        featureFlag: FeatureFlagCollection.scimFunctionality,
      },
    ],
    navTitle: 'tools_and_integrations',
  },
  {
    id: NavigationItem.administration,
    iconName: Icons.FolderGear,
    label: 'administration',
    children: [
      {
        id: NavigationItem.administration_organizations,
        label: 'tenants',
        requiredSecurity: RequiredSecurities.Tenants_Read,
        tenantlessOnly: true,
        children: [
          {
            id: NavigationItem.administration_my_organizations,
            label: 'my_organizations',
            routerLink: `/${Api.admin}/${Api.organizations}/${Api.my}`,
            requiredSecurity: RequiredSecurities.Tenants_Read,
            tenantlessOnly: true,
            displayedOutsideOfNavbar: true,
          },
          {
            id: NavigationItem.administration_all_organizations,
            label: 'all_organizations',
            routerLink: `/${Api.admin}/${Api.organizations}/${Api.all}`,
            requiredSecurity: RequiredSecurities.Tenants_Read,
            tenantlessOnly: true,
            displayedOutsideOfNavbar: true,
          },
        ],
      },
      {
        id: NavigationItem.administration_users,
        label: 'users',
        requiredSecurity: SecurityUtility.mergeRequiredSecurities([
          RequiredSecurities.Global_Users_Read,
          RequiredSecurities.UM_User_Administration_Read_No_Entity_Level,
        ]),
        children: [
          {
            id: NavigationItem.administration_global_users,
            label: 'global_users',
            routerLink: `/${Api.admin}/${Api.globalUsers}`,
            requiredSecurity: RequiredSecurities.Global_Users_Read,
            tenantlessOnly: true,
            displayedOutsideOfNavbar: true,
          },
          {
            id: NavigationItem.administration_internal_users,
            label: 'internal_users',
            routerLink: `/${Api.admin}/${Api.userManagement}/${Api.internal_users}`,
            requiredSecurity: RequiredSecurities.UM_User_Administration_Read_No_Entity_Level,
            tenantlessOnly: true,
            displayedOutsideOfNavbar: true,
          },
          {
            id: NavigationItem.administration_admin_groups,
            label: 'admin_groups',
            routerLink: `/${Api.admin}/${Api.userGroups}`,
            requiredSecurity: RequiredSecurities.UM_User_Administration_Read_No_Entity_Level,
            tenantlessOnly: true,
            displayedOutsideOfNavbar: true,
          },
        ],
      },
    ],
    navTitle: 'administration',
  },
  {
    id: NavigationItem.internal_use_only,
    iconName: Icons.Gavel,
    label: 'system_maintenance_dropdown_menu_item_label',
    children: [
      {
        id: NavigationItem.master_library,
        label: 'master_library',
        routerLink: `/${Api.admin}/${Api.master_library}`,
        requiredSecurity: RequiredSecurities.Master_Library_Read,
        tenantlessOnly: true,
      },
      {
        id: NavigationItem.active_scans,
        label: 'active_scans_navigation_item_label',
        routerLink: `/${Api.admin}/${Api.active_scans}`,
        requiredSecurity: RequiredSecurities.Admin_Active_Scans_Read,
        tenantlessOnly: true,
      },
      {
        id: NavigationItem.translations,
        label: 'administrator_translations',
        routerLink: `/${Api.admin}/${Api.translations}`,
        requiredSecurity: RequiredSecurities.Translations_Read,
        tenantlessOnly: true,
      },
    ],
    navTitle: 'system_maintenance_dropdown_menu_item_label',
  },
  {
    id: NavigationItem.organization_dashboard,
    label: 'organization',
    iconName: Icons.Building,
    routerLink: `/${Api.organization}`,
    requiredSecurity: RequiredSecurities.GR_Organization_Dashboard_Read,
    children: [
      {
        id: NavigationItem.organization_portfolio,
        label: 'organization_portfolio',
        requiredSecurity: RequiredSecurities.Organization_Portfolio_Read,
        featureFlag: FeatureFlagCollection.organizationPortfolio,
        routerLink: `/${Api.organization}/${Api.portfolio}`,
      },
      {
        id: NavigationItem.program_dashboard,
        label: 'program_dashboard',
        requiredSecurity: RequiredSecurities.GR_Organization_Dashboard_Read,
        featureFlag: FeatureFlagCollection.organizationPortfolio,
        routerLink: `/${Api.organization}`,
      },
    ],
  },
  {
    id: NavigationItem.workspace,
    label: 'sidebar_menu_workspaces', // The workspace dropdown menu items are generated dynamically based on user workspaces
    iconName: Icons.Briefcase,
    mobileModalColorVariant: DsModalColorVariant.LightBlue,
    overrideRouterLink: `/${Api.portfolio}`,
    children: [
      {
        id: NavigationItem.workspace_dashboard,
        label: 'workspace_dashboard_translation_key',
        requiredSecurity: RequiredSecurities.GR_Governance_Read,
        restrictByWorkspace: true,
        routerLink: `/${Api.overview}`,
      },
      {
        id: NavigationItem.websites_and_apps,
        label: 'websites_and_apps_translation_key',
        iconName: Icons.Browser,
        children: [
          {
            id: NavigationItem.websites_and_apps_portfolio,
            label: 'heading_menu_portfolio', // Never displayed, this is just here to comply with translations to remove warning logs
            routerLink: `/${Api.portfolio}`,
            isHidden: true,
            tenantOnly: true,
          },
          {
            id: NavigationItem.websites_and_apps_overview,
            label: 'overview',
            requiredSecurity: RequiredSecurities.GR_Dashboard_Read,
            routerLink: `/${Api.dashboard}`,
          },
          {
            id: NavigationItem.websites_and_apps_monitoring,
            label: 'label_monitoring',
            requiredSecurity: RequiredSecurities.GR_Monitoring_Read,
            routerLink: `/${Api.monitoring}`,
            filters: [webDpFilter],
          },
          {
            id: NavigationItem.websites_and_apps_scans,
            label: 'website_and_apps_scans_navigation_item_label',
            isAvailableFn: ({ packageName }: { packageName: PackageNames }): boolean => packageName !== PackageNames.Audit,
            filters: [webDpFilter],
            children: [
              {
                id: NavigationItem.websites_and_apps_scans_page_scans,
                label: 'scans',
                requiredSecurity: RequiredSecurities.AT_Scans_Read,
                routerLink: `/${Api.websitesAndApps}/${Api.scans}/${Api.page_scans}`,
                displayedOutsideOfNavbar: true,
              },
              {
                id: NavigationItem.websites_and_apps_scans_user_flows,
                label: 'user_flows',
                requiredSecurity: RequiredSecurities.Automated_Flows_Read,
                featureFlag: FeatureFlagCollection.userFlows,
                routerLink: `/${Api.websitesAndApps}/${Api.scans}/${Api.user_flows}`,
                filters: [webDpFilter],
                displayedOutsideOfNavbar: true,
              },
              {
                id: NavigationItem.websites_and_apps_scans_components,
                label: 'match_components',
                requiredSecurity: RequiredSecurities.Component_Matching_Read,
                routerLink: `/${Api.websitesAndApps}/${Api.scans}/${Api.components}`,
                filters: [webDpFilter],
                displayedOutsideOfNavbar: true,
              },
            ],
          },
          {
            id: NavigationItem.websites_and_apps_mobile_scans,
            label: 'website_and_apps_scans_navigation_item_label',
            isAvailableFn: ({ packageName }: { packageName: PackageNames }): boolean => packageName !== PackageNames.Audit,
            filters: [mobileDpFilter],
            children: [
              {
                id: NavigationItem.websites_and_apps_mobile_scans_page_scans,
                label: 'mobile_app_scans',
                requiredSecurity: RequiredSecurities.AT_Scans_Read,
                routerLink: `/${Api.websitesAndApps}/${Api.mobile_scans}`,
                displayedOutsideOfNavbar: true,
              },
            ],
            featureFlag: FeatureFlagCollection.mobileAppScans,
          },
          {
            id: NavigationItem.websites_and_apps_manual_evaluations,
            label: 'evaluations_dropdown_menu_item_label',
            requiredSecurity: RequiredSecurities.MT_Manual_Evaluation_Read,
            children: [
              {
                id: NavigationItem.websites_and_apps_manual_evaluations_table,
                label: 'evaluations_dropdown_menu_item_label',
                routerLink: `/${Api.manual_evaluations}`,
              },
              {
                id: NavigationItem.websites_and_apps_manual_evaluations_requests,
                label: 'evaluations_requests',
                routerLink: `/${Api.manual_evaluations}/${Api.requests}`,
              },
            ],
          },
          {
            id: NavigationItem.workspace_design_evaluations,
            label: 'workspace_design_evaluations_navigation_item_label',
            routerLink: `/${Api.designEvaluations}`,
            requiredSecurity: RequiredSecurities.DE_Results_Read,
          },
          {
            id: NavigationItem.websites_and_apps_settings,
            label: 'scan_settings_tooltip_label',
            children: [
              {
                id: NavigationItem.websites_and_apps_environments,
                label: 'environments_label',
                requiredSecurity: RequiredSecurities.Environment_Read,
                featureFlag: FeatureFlagCollection.continuumIntegration,
                routerLink: `/${Api.settings}/${Api.environments}`,
                iconName: Icons.ChartColumn,
              },
              {
                id: NavigationItem.websites_and_apps_scan_rules,
                label: 'scan_rules_label',
                requiredSecurity: RequiredSecurities.Evaluation_Rules_Read,
                routerLink: `/${Api.settings}/${Api.evaluation_rules}`,
                filters: [webDpFilter],
              },
              {
                id: NavigationItem.websites_and_apps_scan_tags,
                label: 'scan_tags_label',
                requiredSecurity: RequiredSecurities.Scan_Tags_Read,
                routerLink: `/${Api.settings}/${Api.scan_tags}`,
                filters: [webDpFilter],
              },
              {
                id: NavigationItem.websites_and_apps_pages,
                label: 'pages',
                requiredSecurity: RequiredSecurities.Pages_Read,
                routerLink: `/${Api.pages}`,
                isAvailableFn: ({ packageName }: { packageName: PackageNames }): boolean => packageName !== PackageNames.Audit,
              },
            ],
          },
        ],
      },
      {
        id: NavigationItem.pdf,
        label: 'pdf_navigation_item_label',
        routerLink: `/${Api.scannableDocuments}`,
        requiredSecurity: RequiredSecurities.PDF_Testing_Read,
      },
      {
        id: NavigationItem.projects_and_tasks,
        label: 'projects_and_tasks',
        children: [
          {
            id: NavigationItem.projects,
            label: 'projects',
            requiredSecurity: RequiredSecurities.PM_Projects_Read,
            routerLink: `/${Api.projects}`,
            displayedOutsideOfNavbar: true,
          },
          {
            id: NavigationItem.my_tasks,
            label: 'my_tasks_label',
            requiredSecurity: RequiredSecurities.PM_Tasks_Read,
            routerLink: `/${Api.myTasks}`,
            displayedOutsideOfNavbar: true,
          },
        ],
      },
      {
        id: NavigationItem.files,
        label: 'files',
        routerLink: `/${Api.documents}`,
        requiredSecurity: RequiredSecurities.Documents_Read,
      },
    ],
  },
  {
    id: NavigationItem.profile,
    label: 'profile_details',
    routerLink: `/${Api.settings}/${Api.profile}`,
    iconName: Icons.User,
    navTitle: 'profile_details',
  },
  {
    id: NavigationItem.logout,
    label: 'logout',
    routerLink: `/${Api.logout}`,
    iconName: Icons.ArrowRightFromBracket,
  },
  {
    id: NavigationItem.notifications,
    label: 'notifications',
    routerLink: `/${Api.notifications}`,
    navTitle: 'notifications',
    iconName: Icons.Bell,
    requiredSecurity: RequiredSecurities.Notifications_Read,
  },
  {
    id: NavigationItem.academy,
    label: 'academy',
    routerLink: `/${Api.academy}`,
    iconName: Icons.GraduationCap,
    requiredSecurity: RequiredSecurities.Academy_Read,
  },
  {
    id: NavigationItem.old_help,
    label: 'label_help',
    iconName: Icons.CircleQuestion,
    children: [
      {
        id: NavigationItem.help_submit_ticket,
        label: 'label_help_submit_ticket',
        iconName: Icons.MessagesQuestion,
        externalLinkFn: (externalLinkService: ExternalLinkService) => externalLinkService.getZendeskSubmitTicketUrl(),
      },
      {
        id: NavigationItem.help_search_knowledge,
        label: 'label_help_search_knowledge',
        iconName: Icons.Book,
        externalLinkFn: (externalLinkService: ExternalLinkService) => externalLinkService.getHelpCenterUrl(),
      },
    ],
  },
  {
    id: NavigationItem.service_request,
    label: 'request_a_service',
    iconName: Icons.MessageArrowUpRight,
    children: [
      {
        id: NavigationItem.service_request_email_consultation,
        label: 'email_consultation',
        iconName: Icons.UniversalAccess,
        externalLinkFn: (externalLinkService: ExternalLinkService) => externalLinkService.getEmailConsultationFormUrl(),
      },
      {
        id: NavigationItem.service_request_manual_evaluation,
        label: 'manual_evaluation',
        iconName: Icons.FileMagnifyingGlass,
        externalLinkFn: (externalLinkService: ExternalLinkService) =>
          externalLinkService.getManualEvaluationServiceRequestFormUrl(),
      },
      {
        id: NavigationItem.service_request_validation_testing,
        label: 'validation_testing',
        iconName: Icons.MessageCheck,
        externalLinkFn: (externalLinkService: ExternalLinkService) =>
          externalLinkService.getValidationTestingServiceRequestFormUrl(),
      },
      {
        id: NavigationItem.service_request_view_all_services,
        label: 'view_all_services',
        externalLinkFn: (externalLinkService: ExternalLinkService) => externalLinkService.getZendeskSubmitTicketUrl(),
      },
    ],
  },
  {
    id: NavigationItem.help,
    label: 'label_help',
    iconName: Icons.CircleQuestion,
    children: [
      {
        id: NavigationItem.help_product_support,
        label: 'product_support',
        iconName: Icons.MessagesQuestion,
        externalLinkFn: (externalLinkService: ExternalLinkService) => externalLinkService.getProductSupportFormUrl(),
      },
      {
        id: NavigationItem.help_help_center,
        label: 'help_center',
        iconName: Icons.Book,
        externalLinkFn: (externalLinkService: ExternalLinkService) => externalLinkService.getHelpCenterUrl(),
      },
      {
        id: NavigationItem.help_roadmap,
        label: 'roadmap',
        iconName: Icons.Map,
        externalLinkFn: (externalLinkService: ExternalLinkService) => externalLinkService.getProductRoadmapUrl(),
      },
    ],
  },
]);
