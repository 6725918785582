<ng-container *ngIf="workspaces$ | async as workspaces">
  <ds-dropdown *ngIf="workspaces?.length > 0" [selectedValue]="selectedWorkspaceId$ | async" >
    <ds-dropdown-toggle
      [variant]="DsButtonVariants.microAction"
      [icon]="Icons.Briefcase"
      label="{{ 'sidebar_menu_workspaces' | translate }}"
    ></ds-dropdown-toggle>
    <div ds-dropdown-menu>
      <ds-dropdown-item
        *ngFor="let workspace of workspaces"
        [label]="workspace[$workspace.name]"
        [href]="getLocationForWorkspace(workspace)"
        [queryParams]="getLinkedPropertyDataQueryParam(workspace)"
        [value]="workspace[$workspace._id]"
      ></ds-dropdown-item>
    </div>
  </ds-dropdown>
</ng-container>
