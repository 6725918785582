import { Component, ComponentRef, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Icons, IconStyles } from '@levelaccess/design-system';

import {
  IAutomatedScanIssue,
  IScanIssueAuditData,
  IScanIssues,
} from '../../../../../../../../shared/interfaces/scan-issues.interface';
import { $scanIssues } from '../../../../../../../../shared/constants/scan-issues';
import {
  ACCESS_ENGINE_SHADOW_DOM_SEPARATOR_MATCHER,
  AccessibilityAuditToolNames,
  IFRAME_DOM_SEPARATOR_MATCHER,
} from '../../../../../../../../shared/constants/audit-tool';
import { RuleAuditHistoryType } from '../../../../../../../../shared/constants/rule-audit-history';
import { ColorContrastRuleSuggestionsComponent } from '../../../../../../components/color-contrast-rule-suggestions/color-contrast-rule-suggestions.component';
import { ModalService } from '../../../../../../services/modal.service';
import { $flaw } from '../../../../../../../../shared/constants/flaw';
import { FlawService } from '../../../../../../services/flaw.service';
import { SharedCommonUtility } from '../../../../../../../../shared/utils/common.utility';
import { UrlFactory } from '../../../../../../components/share-issue-report-url/share-issue-report-url.component';
import { ScanReportService } from '../../../../../../services/scan-report.service';
import { $auditFinding } from '../../../../../../../../shared/constants/audit-finding';
import { TranslateService } from '../../../../../../translate/translate.service';
import { CommonUtility } from '../../../../../../utility/common.utility';
import { AlertType } from '../../../../../../components/common-alert-box/common-alert-box.component';
import { UserDigitalPropertyService } from '../../../../../../services/user-digital-property.service';

@Component({
  selector: 'app-issues-view-details',
  templateUrl: './issues-view-details.component.html',
  styleUrls: ['./issues-view-details.component.scss'],
})
export class IssuesViewDetailsComponent {
  @Input()
  public issue: IAutomatedScanIssue;
  @Input()
  public ruleDetails: IScanIssues;
  @Input()
  public scanId: string;
  @Input()
  public toolName: AccessibilityAuditToolNames;

  public issueType: RuleAuditHistoryType = RuleAuditHistoryType.issue;
  public $scanIssues: typeof $scanIssues;
  public $flaw: typeof $flaw;
  public url: UrlFactory;
  public readonly AccessibilityAuditToolNames: typeof AccessibilityAuditToolNames;

  public selectorId: string;
  public nodesHTMLId: string;

  public readonly AlertType: typeof AlertType = AlertType;
  public readonly Icons: typeof Icons = Icons;
  public readonly IconStyles: typeof IconStyles = IconStyles;

  constructor(
    private modalService: ModalService,
    private flawService: FlawService,
    private scanReportService: ScanReportService,
    private translateService: TranslateService,
    private userDigitalPropertyService: UserDigitalPropertyService,
  ) {
    this.$scanIssues = $scanIssues;
    this.$flaw = $flaw;
    this.url = async (): Promise<string> => {
      return this.scanReportService.getIssueShortLink(
        this.scanId,
        this.toolName,
        this.ruleDetails[$scanIssues.ruleId],
        this.issue.rawFindingId,
      );
    };
    this.AccessibilityAuditToolNames = AccessibilityAuditToolNames;
    this.selectorId = CommonUtility.createUniqueDOMId('selectorId');
    this.nodesHTMLId = CommonUtility.createUniqueDOMId('nodesHTMLId');
  }

  public openColorContrastModal(): void {
    const modalComponentRef: ComponentRef<ColorContrastRuleSuggestionsComponent> = this.modalService.open(
      ColorContrastRuleSuggestionsComponent,
    );

    modalComponentRef.instance.modalInputData = {
      data: this.issue,
    };
  }

  public isColorContrastRule(issue: IAutomatedScanIssue): boolean {
    const data: IScanIssueAuditData = issue[$flaw.data][$auditFinding.auditData];

    const hasColorContrastData = (issueData: IScanIssueAuditData): boolean => {
      return 'contrastBackground' in issueData && 'contrastColor' in issueData;
    };

    return SharedCommonUtility.isNullish(data) === false && hasColorContrastData(data);
  }

  public getFlawPreviousStatus(): string {
    return this.flawService.getPreviousStatus(this.issue);
  }

  public getFlawComponentName(): string {
    if (SharedCommonUtility.notNullish(this.issue?.data?.componentData?.componentName)) {
      return this.issue.data.componentData.componentName;
    }

    const noComponentBody: string = this.translateService.instant('not_associated_with_a_component');
    return `<${noComponentBody}>`;
  }

  public get isElementFromShadowDom(): boolean {
    return ACCESS_ENGINE_SHADOW_DOM_SEPARATOR_MATCHER.test(this.issue.data[$auditFinding.elementReference]);
  }

  public get isElementFromIframe(): boolean {
    return IFRAME_DOM_SEPARATOR_MATCHER.test(this.issue.data[$auditFinding.elementReference]);
  }

  public get isMobileType$(): Observable<boolean> {
    return this.userDigitalPropertyService.isMobileDigitalProperty$;
  }
}
