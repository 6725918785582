import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

import { RestService } from './rest.service';
import { TranslateService } from '../translate/translate.service';
import { DateUtility } from '../utility/date.utility';
import {
  IActiveScan,
  IGetScansQuery,
  IGetScansServerResponse,
  IQuickScanOptions,
  IScan,
} from '../../../shared/interfaces/scan.interface';
import { IAudits } from '../../../shared/interfaces/audits.interface';
import { $scanOptions } from '../../../shared/constants/scan-options';
import { ICurrentSelectedProperty } from './user.service';
import { standardNameAndLevel } from '../../../shared/constants/scanning';
import { AuditTypes } from '../../../shared/constants/audit-types';
import { IAudit } from '../../../shared/interfaces/audit.interface';
import { IScanCreateRequest } from '../../../shared/interfaces/scan-create-request.interface';
import {
  IAnalyticsByRuleResult,
  IAnalyticsResultItem,
  IPageAffectedByRuleAnalytics,
} from '../../../shared/interfaces/analytics.interface';
import { UserPropertyService } from './user-property.service';
import { AuditStandards } from '../../../shared/constants/audit-standard';
import { AccessibilityAuditToolNames } from '../../../shared/constants/audit-tool';
import { SharedDateUtility } from '../../../shared/utils/date.utility';
import { IDiscoveredScannableDocumentResponse } from '../../../shared/interfaces/scannable-document.interface';
import { IAuditedDocument } from '../../../shared/interfaces/audited-document.interface';

export enum ScanCreationType {
  advanced,
  quick,
}

interface ScanCreationInputsMap {
  [ScanCreationType.advanced]: FormData;
  [ScanCreationType.quick]: IQuickScanOptions;
}

@Injectable({
  providedIn: 'root',
})
export class ScanService {
  private scanCreatedSubject: Subject<IScan>;
  public onScanCreated$: Observable<IScan>;

  constructor(
    private restService: RestService,
    private translateService: TranslateService,
    private userPropertyService: UserPropertyService,
  ) {
    this.scanCreatedSubject = new Subject<IScan>();
    this.onScanCreated$ = this.scanCreatedSubject.asObservable();
  }

  private createAdvancedScan(workspaceId: string, digitalPropertyId: string, formData: FormData): Observable<IScan> {
    return this.restService.createScan(workspaceId, digitalPropertyId, formData);
  }

  private createQuickScan(workspaceId: string, digitalPropertyId: string, options: IQuickScanOptions): Observable<IScan> {
    return this.restService.createAutomatedScan(workspaceId, digitalPropertyId, options);
  }

  public getScans(
    searchParams: IGetScansQuery,
    limit: number,
    skip: number,
    sortBy: string = null,
  ): Observable<IGetScansServerResponse> {
    return this.userPropertyService
      .currentSelectedProperty()
      .pipe(
        mergeMap(
          ({ digitalPropertyId, workspaceId }: ICurrentSelectedProperty): Observable<IGetScansServerResponse> =>
            this.restService.getScans(workspaceId, digitalPropertyId, searchParams, limit, skip, sortBy),
        ),
      );
  }

  public getScan(scanId: string): Observable<IScan> {
    return this.userPropertyService
      .currentSelectedProperty()
      .pipe(
        mergeMap(
          ({ workspaceId, digitalPropertyId }: ICurrentSelectedProperty): Observable<IScan> =>
            this.restService.getScan(workspaceId, digitalPropertyId, scanId),
        ),
      );
  }

  public getAuditedDocument(scanId: string, url: string): Observable<IAuditedDocument> {
    return this.userPropertyService
      .currentSelectedProperty()
      .pipe(
        mergeMap(
          ({ workspaceId, digitalPropertyId }: ICurrentSelectedProperty): Observable<IAuditedDocument> =>
            this.restService.getAuditedDocument(workspaceId, digitalPropertyId, scanId, url),
        ),
      );
  }

  public getScanAnalytics(
    scanHistoryLimit: number,
    scanId?: string,
    toolName?: AccessibilityAuditToolNames,
  ): Observable<IAnalyticsResultItem[]> {
    return this.userPropertyService
      .currentSelectedProperty()
      .pipe(
        mergeMap(({ workspaceId, digitalPropertyId }: ICurrentSelectedProperty) =>
          this.restService.getScanAnalytics(workspaceId, digitalPropertyId, scanId, toolName, scanHistoryLimit),
        ),
      );
  }

  public getScanAnalyticsByRule(): Observable<IAnalyticsByRuleResult> {
    return this.userPropertyService
      .currentSelectedProperty()
      .pipe(
        mergeMap(({ workspaceId, digitalPropertyId }: ICurrentSelectedProperty) =>
          this.restService.getScanAnalyticsByRule(workspaceId, digitalPropertyId),
        ),
      );
  }

  public getPageAnalyticsAffectedByRule(ruleId: string): Observable<IPageAffectedByRuleAnalytics[]> {
    return this.userPropertyService
      .currentSelectedProperty()
      .pipe(
        mergeMap(({ workspaceId, digitalPropertyId }: ICurrentSelectedProperty) =>
          this.restService.getPageAnalyticsAffectedByRule(workspaceId, digitalPropertyId, ruleId),
        ),
      );
  }

  public createScan<T extends keyof ScanCreationInputsMap>(scanType: T, form: ScanCreationInputsMap[T]): Observable<IScan> {
    const createScan = ({ workspaceId, digitalPropertyId }: ICurrentSelectedProperty): Observable<IScan> => {
      if (scanType === ScanCreationType.quick) {
        return this.createQuickScan(workspaceId, digitalPropertyId, form as IQuickScanOptions);
      }
      return this.createAdvancedScan(workspaceId, digitalPropertyId, form as FormData);
    };

    const emitScanCreated = (scan: IScan): void => this.scanCreatedSubject.next(scan);

    return this.userPropertyService.currentSelectedProperty().pipe(mergeMap(createScan), tap(emitScanCreated));
  }

  public updateScan(workspaceId: string, digitalPropertyId: string, formData: IScanCreateRequest, id: string): Observable<IScan> {
    return this.restService.updateScan(workspaceId, digitalPropertyId, formData, id);
  }

  public getOrCreateSupplementalScan(id: string, url: string): Observable<string> {
    return this.userPropertyService
      .currentSelectedProperty()
      .pipe(
        mergeMap(({ digitalPropertyId, workspaceId }: ICurrentSelectedProperty) =>
          this.restService.getOrCreateSupplementalScan(workspaceId, digitalPropertyId, id, url),
        ),
      );
  }

  public removeScan(workspaceId: string, digitalPropertyId: string, id: string): Observable<IScan> {
    return this.restService.removeScan(workspaceId, digitalPropertyId, id);
  }

  public rerunScan(workspaceId: string, digitalPropertyId: string, id: string): Observable<IScan> {
    return this.restService.rerunScan(workspaceId, digitalPropertyId, id);
  }

  public getActiveScans(skip: number, limit: number): Observable<IActiveScan[]> {
    return this.restService.getActiveScans(skip, limit);
  }

  public getDigitalPropertyActiveScans(workspaceId: string, digitalPropertyId: string): Observable<IActiveScan> {
    return this.restService.getDigitalPropertyActiveScans(workspaceId, digitalPropertyId);
  }

  public totalScanningTime(processingStartedAt: string, processingEndedAt: string): string {
    const start: number = new Date(processingStartedAt).getTime();
    const end: number = new Date(processingEndedAt).getTime();

    return DateUtility.getFormattedTime(end - start);
  }

  public totalScanningTimeAriaLabel(processingStartedAt: string, processingEndedAt: string): string {
    const value: string = this.totalScanningTime(processingStartedAt, processingEndedAt);

    const translate = (key: string): string => `$1 ${this.translateService.instant(key)}`;
    return value
      .replace('<', this.translateService.instant('less_than'))
      .replace(/(\s+)ms/, translate('millisecond'))
      .replace(/(\d+)m/, translate('minutes'))
      .replace(/(\d+)s/, translate('seconds'))
      .replace(/(\d+)h/, translate('hours'));
  }

  public getSelectedAudits(audits: IAudits): string[] {
    const getOnlySelectedAudits = (filtered: string[], auditId: AuditTypes): string[] => {
      const audit: IAudit = audits[auditId];

      if (audit.hidden) {
        return filtered;
      }

      if (audit.isSelectedForScanning) {
        filtered.push(audit[$scanOptions.name]);
      }

      return filtered;
    };

    return Object.keys(audits).reduce(getOnlySelectedAudits, []);
  }

  public getNextScheduledScan(): Observable<IScan> {
    return this.userPropertyService
      .currentSelectedProperty()
      .pipe(
        mergeMap(({ workspaceId, digitalPropertyId }: ICurrentSelectedProperty) =>
          this.restService.getNextScheduledScan(workspaceId, digitalPropertyId),
        ),
      );
  }

  public getTranslatedConformanceLevel(includesStandard: any, includesEssential: boolean): string {
    if (includesStandard === standardNameAndLevel.essential_v10_A && includesEssential === true) {
      return AuditStandards.essential;
    }

    return this.translateService.instant(includesStandard);
  }

  public getTranslatedFormattedDateTimeValue(prefixLabel: string, dateTime: string): string | undefined {
    if (dateTime === null) {
      return undefined;
    }

    let dateValue: string;
    const date: Date = new Date(dateTime);
    const formatted: string = date.toLocaleString([], { hour: '2-digit', minute: '2-digit', timeZoneName: 'short' });

    if (SharedDateUtility.isToday(date)) {
      dateValue = `${this.translateService.instant('scan_progress_today')} ${this.translateService.instant('at')} ${formatted}`;
    } else if (SharedDateUtility.isYesterday(date)) {
      dateValue = `${this.translateService.instant('scan_progress_yesterday')} ${this.translateService.instant(
        'at',
      )} ${formatted}`;
    } else {
      dateValue = `${this.translateService.instant('at')} ${DateUtility.getFormattedISODate(date.toISOString(), true, [], true)}`;
    }

    return `${this.translateService.instant(prefixLabel, dateValue)}`;
  }

  public moveScans(toDigitalPropertyId: string, scanIds: string[]): Observable<void> {
    return this.userPropertyService
      .currentSelectedProperty()
      .pipe(
        mergeMap(({ workspaceId, digitalPropertyId }: ICurrentSelectedProperty) =>
          this.restService.moveScans(workspaceId, digitalPropertyId, toDigitalPropertyId, scanIds),
        ),
      );
  }

  public getSelectedWorkspaceScansCount(excludeMonitoring: boolean = false): Observable<number> {
    return this.userPropertyService.currentSelectedProperty().pipe(
      mergeMap(({ workspaceId, digitalPropertyId }: ICurrentSelectedProperty): Observable<number> => {
        return this.restService.getScansCount(workspaceId, digitalPropertyId, excludeMonitoring);
      }),
    );
  }

  public updateScanTags(scanTagId: string, scanIds: string[]): Observable<void> {
    return this.userPropertyService.currentSelectedProperty().pipe(
      mergeMap(({ workspaceId, digitalPropertyId }: ICurrentSelectedProperty): Observable<void> => {
        return this.restService.updateScanTags(workspaceId, digitalPropertyId, scanTagId, scanIds);
      }),
    );
  }

  public getScanDiscoveredDocuments(scanId: string): Observable<IDiscoveredScannableDocumentResponse> {
    return this.userPropertyService
      .currentSelectedProperty()
      .pipe(
        mergeMap(
          ({ workspaceId, digitalPropertyId }: ICurrentSelectedProperty): Observable<IDiscoveredScannableDocumentResponse> =>
            this.restService.getScanDiscoveredDocuments(workspaceId, digitalPropertyId, scanId),
        ),
      );
  }
}
