import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { isEqual } from 'lodash';

import {
  IActionColumnData,
  ICheckboxColumnData,
  ICustomCellType,
  ICustomColumnsData,
  ICustomTableAction,
  ICustomTableColumn,
  ICustomTableColumnState,
  ICustomTableConfiguration,
  ICustomTableRow,
  ICustomTableRowCellConfig,
  ICustomTableState,
  IImageColumnData,
  IAttachmentColumnData,
  ILinkColumnData,
  IProfilePictureColumnData,
  IProgressColumnData,
  IRemediationProgressColumnData,
  ITextWithTagColumnData,
  ITooltipColumnData,
  IScoreColumnData,
} from './custom-table.interface';
import { $sortingOrder } from '../../../../../shared/constants/sort';
import { customColumnType } from './constants';
import { TranslateService } from '../../../translate/translate.service';
import { tableEmptyStateType } from './empty-state/table-empty-state.component';
import { $severity } from '../../../../../shared/constants/accessibility';
import { IMonitoringLabel } from '../../monitoring-label/monitoring-label.interface';
import { SharedCommonUtility } from '../../../../../shared/utils/common.utility';
import { CommonUtility } from '../../../utility/common.utility';
import { $invitationStatus } from '../../../../../shared/constants/workspace-user.constants';
import { IDropdownCellConfig, IDropdownItem } from '../ngb-table/cells/dropdown-cell/dropdown-cell.component';

const sortingStatesOrder: readonly $sortingOrder[] = Object.freeze([$sortingOrder.all, $sortingOrder.asc, $sortingOrder.desc]);

/**
 * Note: table configuration, data and state are using same data structure like so: tableState.columns[columnId][customField]
 * Usage and configuration examples can be found in custom-table.component.spec.ts file
 *
 * @deprecated use {@link NgbTableComponent} instead
 */
@Component({
  selector: 'app-custom-table',
  templateUrl: './custom-table.component.html',
  styleUrls: ['./custom-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomTableComponent implements OnChanges, OnInit {
  private _rowsData: ICustomTableRow[];

  @Input() public config: ICustomTableConfiguration;
  @Input() public actions: ICustomTableAction[];
  @Input() public tableCaption: string;
  @Input() public emptyState?: tableEmptyStateType;
  @Input() public emptyStateHideBorders?: boolean;
  @Input() public defaultSortOrder?: $sortingOrder;
  @Input() public tableRowsAreCheckable?: boolean;

  @Output() public onTableStateChanged: EventEmitter<ICustomTableState>;
  @Output() public onTableRowChecked: EventEmitter<boolean | number[]>;

  public $sortingOrder: typeof $sortingOrder;
  public $severity: typeof $severity;
  public $invitationStatus: typeof $invitationStatus;

  @Input()
  public set rowsData(rowsData: ICustomTableRow[]) {
    this._rowsData = rowsData;

    for (const row of rowsData) {
      if (typeof row._uid === 'undefined') {
        row._uid = CommonUtility.createUniqueDOMId();
      }
    }
  }

  public get rowsData(): ICustomTableRow[] {
    return this._rowsData;
  }

  public tableState: ICustomTableState;
  public customColumnType: typeof customColumnType;
  public tableDataAvailable: boolean;
  public columnsList: string[];
  public checkedAll: boolean;
  public checkedRows: { [key: number]: boolean };
  public hasSortableColumns: boolean;

  constructor(private translateService: TranslateService) {
    this.customColumnType = customColumnType;
    this.$sortingOrder = $sortingOrder;
    this.$severity = $severity;
    this.$invitationStatus = $invitationStatus;

    this.onTableStateChanged = new EventEmitter<ICustomTableState>();
    this.onTableRowChecked = new EventEmitter<boolean | number[]>();
    this.columnsList = [];
    this.checkedAll = false;
    this.checkedRows = {};
    this.tableDataAvailable = false;

    this.tableState = {
      columns: {},
    };
  }

  private refreshColumnState(): void {
    this.tableDataAvailable = this.isTableDataAvailable();
    this.columnsList = this.getColumnsList();
    if (this.tableDataAvailable === false) {
      return;
    }

    const newTableState: ICustomTableState = { columns: {} };

    for (const columnIndex in this.config.columns) {
      if (typeof this.tableState.columns[columnIndex] !== 'undefined') {
        newTableState.columns[columnIndex] = this.tableState.columns[columnIndex];
        continue;
      }

      const newState: ICustomTableColumnState = {};

      if (this.config.columns[columnIndex].sortingEnabled) {
        newState.sortingOrder = $sortingOrder.all;
      }

      newTableState.columns[columnIndex] = newState;
    }

    this.tableState = newTableState;
  }

  private getCustomTableCellData<T extends customColumnType>(columnKey: string, rowIndex: number): ICustomCellType[T] | null {
    if (this.tableDataAvailable === false) {
      return null;
    }

    if (this.rowsData[rowIndex] === undefined) {
      return null;
    }

    const columns: ICustomColumnsData = this.rowsData[rowIndex].customColumns ?? this.rowsData[rowIndex].columns;
    if (columns === undefined) {
      return null;
    }

    if (columns[columnKey] === undefined) {
      return null;
    }

    return columns[columnKey] as ICustomCellType[T];
  }

  private resetColumnsSorting(): void {
    for (const columnKey in this.tableState.columns) {
      if (this.getColumnConfig(columnKey).sortingEnabled !== true) {
        continue;
      }

      this.tableState.columns[columnKey].sortingOrder = $sortingOrder.all;
    }
  }

  private triggerTableStateChangeEvent(): void {
    this.onTableStateChanged.emit(this.tableState);
  }

  private isTableDataAvailable(): boolean {
    if (SharedCommonUtility.getTypeOf(this.config) !== 'object' || Array.isArray(this.rowsData) === false) {
      return false;
    }

    if (Object.keys(this.config.columns).length === 0) {
      return false;
    }

    return true;
  }

  private getColumnsList(): string[] {
    if (this.tableDataAvailable === false) {
      return [];
    }

    return Object.keys(this.config.columns);
  }

  public sortingTriggered(columnKey: string): void {
    const columnState: ICustomTableColumnState = this.getColumnState(columnKey);
    let sortingOrderIndex: number = sortingStatesOrder.indexOf(columnState.sortingOrder);

    if (sortingOrderIndex === -1) {
      return;
    }

    sortingOrderIndex += 1;

    if (sortingOrderIndex >= sortingStatesOrder.length) {
      sortingOrderIndex = 0;
    }

    this.resetColumnsSorting();

    const updatedSortingOrder: $sortingOrder = sortingStatesOrder[sortingOrderIndex];
    this.tableState.columns[columnKey].sortingOrder = updatedSortingOrder;

    this.triggerTableStateChangeEvent();
  }

  public getColumnState(columnKey: string): ICustomTableColumnState {
    if (this.tableState.columns[columnKey] === undefined) {
      return {};
    }

    return this.tableState.columns[columnKey];
  }

  public getCellData(columnKey: string, rowIndex: number): string {
    if (this.tableDataAvailable === false) {
      return '';
    }

    if (this.rowsData[rowIndex] === undefined) {
      return '';
    }

    return this.rowsData[rowIndex].columns[columnKey];
  }

  public getCellDataForMonitoring(columnKey: string, rowIndex: number): IMonitoringLabel {
    if (this.tableDataAvailable === false) {
      return null;
    }

    if (this.rowsData[rowIndex] === undefined) {
      return null;
    }

    return this.rowsData[rowIndex].columns[columnKey];
  }

  public getScoreCellData(columnKey: string, rowIndex: number): IScoreColumnData {
    const cellData: IScoreColumnData = this.getCustomTableCellData<customColumnType.score>(columnKey, rowIndex);
    const score: number = SharedCommonUtility.notNullish(cellData?.score) ? Number(cellData.score) : -1;
    const emptyStateLabel: string = cellData?.emptyStateLabel;
    return { score, emptyStateLabel };
  }

  public getScoreCellClass(columnKey: string, rowIndex: number): Record<string, boolean> {
    const cellData: IScoreColumnData = this.getScoreCellData(columnKey, rowIndex);
    const numData: number = Number(cellData.score);

    return {
      high: numData >= 80,
      medium: numData >= 50,
      low: numData < 50,
    };
  }

  public getScoreImpactCellData(columnKey: string, rowIndex: number, absolute: boolean = false): number {
    const res: number = parseFloat((parseFloat(this.getCellData(columnKey, rowIndex)) * 100).toFixed(2));

    return absolute ? Math.abs(res) : res;
  }

  public getColumnConfig(columnKey: string): ICustomTableColumn {
    return this.config.columns[columnKey];
  }

  public getArrowsContainerClasses(columnKey: string): string {
    const columnState: ICustomTableColumnState = this.getColumnState(columnKey);

    if (columnState.sortingOrder === $sortingOrder.asc) {
      return `arrow-ascending`;
    }

    if (columnState.sortingOrder === $sortingOrder.desc) {
      return `arrow-descending`;
    }

    return '';
  }

  public getTableHeaderAriaSort(columnKey: string): string | undefined {
    if (!this.getColumnConfig(columnKey)?.sortingEnabled) {
      return undefined;
    }

    const columnState: ICustomTableColumnState = this.getColumnState(columnKey);

    return this.getSortOrderText(columnState.sortingOrder);
  }

  public getSortOrderText(sortingOrder: $sortingOrder): string {
    if (sortingOrder === $sortingOrder.asc) {
      return this.translateService.instant('ascending');
    }

    if (sortingOrder === $sortingOrder.desc) {
      return this.translateService.instant('descending');
    }

    return this.translateService.instant('none');
  }

  public getTableHeaderAriaSortLabel(columnKey: string): string {
    const sortCol = this.translateService.instant(this.config.columns[columnKey]?.translationKey);
    const sortDir = this.getTableHeaderAriaSort(columnKey).toLowerCase();
    const sortTxt = this.translateService.instant('label_sort').toLowerCase();
    return `${sortCol} ${sortDir} ${sortTxt}`;
  }

  /* Note: for custom columns */

  // For downloads and external links, see getExternalLinkCellData
  public getLinkCellData(columnKey: string, rowIndex: number): ILinkColumnData {
    const result: ILinkColumnData | null = this.getCustomTableCellData<customColumnType.link>(columnKey, rowIndex);

    if (result === null) {
      return {
        linkLabel: '',
        href: '#',
        queryParams: {},
        disabled: false,
      };
    }

    return result;
  }

  public getActionCellData(columnKey: string, rowIndex: number): IActionColumnData {
    const result: IActionColumnData | null = this.getCustomTableCellData<customColumnType.action>(columnKey, rowIndex);

    if (result === null) {
      return {
        action: (): void => {},
        label: '',
      };
    }

    return result;
  }

  public getProgressCellAsPercent(columnKey: string, rowIndex: number): string {
    const progress: IProgressColumnData = this.getProgressCellData(columnKey, rowIndex);
    return `${progress.progress}%`;
  }

  public getProgressCellData(columnKey: string, rowIndex: number): IProgressColumnData {
    return this.getCustomTableCellData<customColumnType.progress>(columnKey, rowIndex) || { current: 0, max: 100, progress: 0 };
  }

  public getRemediationProgressCellData(columnKey: string, rowIndex: number): IRemediationProgressColumnData {
    return this.getCustomTableCellData<customColumnType.remediationProgress>(columnKey, rowIndex);
  }

  public getImageCellData(columnKey: string, rowIndex: number): IImageColumnData {
    return this.getCustomTableCellData<customColumnType.img>(columnKey, rowIndex) || { url: '', embedImage: false };
  }

  public getAttachmentsCellData(columnKey: string, rowIndex: number): IAttachmentColumnData[] {
    return (
      this.getCustomTableCellData<customColumnType.attachments>(columnKey, rowIndex) || [
        { icon: 'attachment-document', href: '' },
      ]
    );
  }

  public getTextWithTagCellData(columnKey: string, rowIndex: number): ITextWithTagColumnData {
    return (
      this.getCustomTableCellData<customColumnType.textWithTag>(columnKey, rowIndex) || {
        text: '',
        tagColor: '',
        tagLabel: '',
      }
    );
  }

  public getToolTipCellData(columnKey: string, rowIndex: number): ITooltipColumnData {
    return (
      this.getCustomTableCellData<customColumnType.tooltip>(columnKey, rowIndex) || {
        message: '',
        tooltipMessage: '',
        tooltipPopupMessage: '',
        hideTooltip: true,
      }
    );
  }

  public getCheckboxCellData(columnKey: string, rowIndex: number): ICheckboxColumnData {
    const result: ICheckboxColumnData | null = this.getCustomTableCellData<customColumnType.checkbox>(columnKey, rowIndex);

    if (result === null) {
      return {
        action: (): void => void 0,
        checked: (): boolean => false,
        disabled: (): boolean => true,
        indeterminate: (): boolean => false,
        label: (): string => '',
      };
    }

    return result;
  }

  public getSeverityCellData(columnKey: string, rowIndex: number): $severity {
    return this.getCustomTableCellData<customColumnType.severity>(columnKey, rowIndex);
  }

  public getProfilePictureCellData(columnKey: string, rowIndex: number): IProfilePictureColumnData {
    return this.getCustomTableCellData<customColumnType.profilePicture>(columnKey, rowIndex);
  }

  public getInvitationStatusCellData(columnKey: string, rowIndex: number): $invitationStatus {
    return this.getCustomTableCellData<customColumnType.invitationStatus>(columnKey, rowIndex);
  }

  public getRemediationProgressAriaLabel(columnKey: string): string {
    const columnText: string = this.translateService.instant(this.config.columns[columnKey]?.translationKey);
    return this.translateService.instant('aria_label_progress_bar', columnText);
  }

  public hasCell(columnKey: string, rowIndex: number): boolean {
    return (
      this.rowsData[rowIndex].columns.hasOwnProperty(columnKey) ||
      this.rowsData[rowIndex].customColumns?.hasOwnProperty(columnKey)
    );
  }

  public getCellConfig(columnKey: string, rowIndex: number): ICustomTableRowCellConfig | undefined {
    return this.rowsData[rowIndex].config?.[columnKey];
  }

  public hasAvailableActions(row: ICustomTableRow): boolean {
    return this.actions.some((action: ICustomTableAction) => {
      return typeof action.hide === 'undefined' || action.hide(row) === false;
    });
  }

  public getActionCellConfig(row: ICustomTableRow): IDropdownCellConfig {
    const dropdownItems: IDropdownItem[] = this.actions
      .map((action: ICustomTableAction): IDropdownItem => {
        if (action.hide && action.hide(row)) {
          return null;
        }
        return {
          label: action.name,
          clickListener: action.handler.bind(action.handler, row),
          disabled: action.disabled && action.disabled(row),
        };
      })
      .filter((val: IDropdownItem | null): boolean => val !== null);

    return {
      ellipsisIcon: true,
      classes: [],
      dropdownItems: dropdownItems,
    };
  }

  public onCheckHead(): void {
    if (this.checkedAll) {
      for (let i = 0; i < this.rowsData.length; i++) {
        this.checkedRows[i] = this.checkedAll;
      }
    } else {
      this.checkedRows = {};
    }
    this.onTableRowChecked.emit(this.checkedAll);
  }

  public onCheckCell(rowIndex: number): void {
    if (this.checkedRows[rowIndex] === false) {
      delete this.checkedRows[rowIndex];
    }
    this.onTableRowChecked.emit(Object.keys(this.checkedRows).map((k: string): number => parseInt(k, 10)));
    this.checkedAll = false;
  }

  public roundDown(num: number): number {
    return Math.floor(num);
  }

  public trackBy(index: number, item: { _uid?: string }): string {
    if (item === null || typeof item === 'undefined') {
      return null;
    }

    if (typeof item._uid === 'string') {
      return item._uid;
    }

    return String(index);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      this.tableRowsAreCheckable &&
      changes.rowsData &&
      !isEqual(changes.rowsData.currentValue, changes.rowsData.previousValue)
    ) {
      this.checkedRows = this.checkedAll
        ? Array.from(Array(this.rowsData.length).keys()).reduce(
            (
              accu: { [key: number]: boolean },
              curr: number,
            ): {
              [key: number]: boolean;
            } => {
              accu[curr] = true;
              return accu;
            },
            {},
          )
        : {};
    }
    this.refreshColumnState();
  }

  public ngOnInit(): void {
    this.hasSortableColumns = Object.values(this.config.columns).some(
      (column: ICustomTableColumn): boolean => column.sortingEnabled,
    );
  }
}
